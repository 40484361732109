import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleResponse } from '../../shared/models/single-response';
import { BaseService } from '../../shared/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidationService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 校验身份证是否存在
   */
  public idCardExists(idCard: string): Observable<SingleResponse<any>> {
    // 请求地址
    const url = `${this.apiUrlBase}/api/breed/${idCard}`;

    return this.http.get<SingleResponse<any>>(url);
  }
}
