export class ChargeModel {
    /**
     * 收费项
     * 1：登记；2：年检
     */
    public chargeItem?: number;
    /**
     * 发票号
     */
    public invoiceNo?: string;
    /**
     * 费用类型
     * 1：免费；2：半价；3：全额
     */
    public chargeType?: number;
    /**
     * 费用标准
     */
    public amount?: number;
    /**
     * 实际收取费用
     */
    public amountReceived?: number;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init?: Partial<ChargeModel>) {
        Object.assign(this, init);
    }
}
