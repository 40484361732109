import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { LimitedAreaStrategyService } from '../../system-manage/services/limited-area-strategy.service';
import { tap, map, shareReplay } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { Observable, of } from 'rxjs';
import { LimitedAreaStrategySearchModel } from '../../system-manage/models/limited-area-strategy-search.model';

@Injectable({
  providedIn: 'root'
})
export class LimitedAreaStrategyStorageService {

  private readonly DICKEY: string = 'LIMITED_AREA_STRATEGY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private limitedAreaStrategyService: LimitedAreaStrategyService
  ) { }

  /**
   * 限养区策略缓存初始化
   */
  public setStorage(): Observable<ListResponse<LimitedAreaStrategySearchModel>> {
    if (!this.http$) {
      this.http$ = this.limitedAreaStrategyService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<LimitedAreaStrategySearchModel>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取限养区策略缓存
   */
  public getStorage(): Observable<Array<LimitedAreaStrategySearchModel>> {

    const result = this.localStorageService.get<Array<LimitedAreaStrategySearchModel>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map((value: ListResponse<LimitedAreaStrategySearchModel>) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    // 拉取数据
    return of(result);
  }

  /**
   * 根据辖区ID获取限养区策略
   * @param areaCode 辖区ID
   */
  public getStrategy(areaCode: string): Observable<LimitedAreaStrategySearchModel> {
    let result = null;

    // 获取字典项
    return this.getStorage().pipe(
      map((value, index: number) => {
        if (!value) {
          return result;
        }

        const item = value.find(x => x.areaCode === areaCode);
        result = item;

        return result;
      })
    );
  }

  /**
   * 根据辖区ID获取限养区策略所对应的限养区类型
   * @param areaCode 辖区编码
   */
  public getAreaType(areaCode: string): Observable<number> {
    let result = 0;

    // 获取字典项
    return this.getStorage().pipe(
      map((value, index: number) => {
        if (!value) {
          return result;
        }

        const item = value.find(x => x.areaCode === areaCode);
        result = item ? item.areaType : 0;

        return result;
      })
    );
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
