import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { SignalRType } from 'src/app/shared/models/enum';
import { BreedService } from 'src/app/canine-manage/services/breed.service';
import { SignalRService, SignalRResponse } from 'src/app/shared/notices/signalr.service';
import { PrintComponent } from '../business/print/print.component';
import { BreedPagedParameter } from 'src/app/canine-query/models/breed-paged.parameter';
import { BreedSearchModel } from 'src/app/canine-query/models/breed-search.model';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.less'],
  providers: [
    DatePipe
  ]
})
export class ToolboxComponent implements OnInit, OnDestroy {
  private printModal: NzModalRef<PrintComponent, any>;

  private getRegisterSubscription: Subscription;

  public visible = false;
  public dataSet: Array<BreedSearchModel>;

  public queryForm: FormGroup;
  public parameter: BreedPagedParameter = new BreedPagedParameter();
  public total: number;

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private signalRService: SignalRService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private breedService: BreedService
  ) { }

  ngOnInit(): void {
    this.queryForm = this.fb.group({
      cardNo: [null]
    });

    this.refreshData();

    // 读卡消息推送
    this.getRegisterSubscription = this.signalRService.getRegisterReceived.subscribe((message: SignalRResponse) => {
      // 检测是否获取到卡信息
      this.ngZone.run(() => {
        // 判断是否读卡成功
        if (message && message.Status === 1 && message.Data && message.Type === SignalRType.ToolBox) {
          this.queryForm.patchValue({
            cardNo: message.Data.RegisterNo.trim()
          });

          // 刷新列表
          this.refreshData();
        } else {
          // 错误提示
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.printModal) {
      this.printModal.destroy();
    }

    // 取消订阅
    if (this.getRegisterSubscription && !this.getRegisterSubscription.closed) {
      this.getRegisterSubscription.unsubscribe();
    }
  }

  open($event: any): void {
    this.visible = !this.visible;
    this.refreshData();
  }

  close(): void {
    this.visible = false;
  }

  /**
   * 二维码图标点击事件
   * @param $event 数据源
   */
  scanClick($event): void {
    // 发送读卡指令
    this.signalRService.sendGetRegister(SignalRType.ToolBox);
  }

  /**
   * 查询
   * @param $envent 事件
   * @param value 表单数据
   */
  querySubmit($envent: any, value: any): void {
    this.refreshData();
  }

  /**
   * 打印事件
   */
  public printClick(registrationNo: string): void {
    this.print(registrationNo);
  }

  /**
   * 办证（打印，写卡）
   * @param ownerType 犬主类型
   */
  public print(registrationNo: string): void {
    this.printModal = this.modalService.create({
      nzTitle: '制证写卡流程',
      nzWidth: '750px',
      nzMaskClosable: false,
      nzContent: PrintComponent,
      nzComponentParams: {
        cardNo: registrationNo,
        isConfirm: false
      },
      nzFooter: null
    });

    this.printModal.afterOpen.subscribe((result) => {
      const component = this.printModal.getContentComponent();
      // 是否加载数据列表
      component.callBack.subscribe(isSuccess => {
        this.printModal.close();
      });
      // 成功回调函数
      component.successCallBack.subscribe(isConfirm => {
        // 刷新列表
        if (!isConfirm) {
          this.refreshData(true);
        }
      });
    });

    this.printModal.afterClose.subscribe((result) => {
      // 关闭窗口
      this.printModal.destroy();
    });
  }

  /**
   * 分页索引改变事件
   * @param pageIndex 分页索引
   */
  pageIndexChange(pageIndex: number): void {
    this.parameter.pageIndex = pageIndex;
    // 加载列表数据
    this.refreshData();
  }

  /**
   * 刷新列表
   */
  refreshData(isPrintNext?: boolean): void {
    const model = this.queryForm.value;
    // 变更
    this.parameter.init({
      likeCondition: model.cardNo,
      // 制卡状态
      // 1：已制卡；2：未制卡
      cardFlag: 2
    });

    this.breedService.paged(this.parameter).subscribe(
      response => {
        // 判断是否成功
        if (response && !response.didError) {
          if (response.model) {
            this.dataSet = response.model;

            this.total = response.itemsCount;

            if (isPrintNext) {
              this.printModal.close();
            }

            // // 判断是否从最上边开始打印
            if (isPrintNext && this.dataSet && this.dataSet.length) {
              const searchModel: BreedSearchModel = this.dataSet[0];
              // 打印最新数据
              setTimeout(() => {
                this.print(searchModel.registrationNo);
              }, 500);
            }
          }
        }
      }
    );
  }
}
