<div style="display:flex; flex-direction: row; height: 75px; width: max-content;">
  <nz-upload
    [ngClass]="{'avatar-uploader': isMini && imgList.length, 'avatar-uploader-card': !isMini && imgList.length, 'default-uploader-card': !isMini && !imgList.length , 'default-uploader': isMini && !imgList.length }"
    [nzAction]="fileApi" nzListType="picture-card" [nzAccept]="['image/*']" [(nzFileList)]="imgList"
    [nzRemove]="handleRemove" [(nzShowButton)]="showButton" [nzShowUploadList]="showUploadList"
    [nzBeforeUpload]="beforeUpload" (nzChange)="photoChange($event)" [nzPreview]="handlePreview">
    <ng-container *ngIf="!model && !isCamera || showButton">
      <div
        style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; background-position: center; flex-direction: column; background-repeat: no-repeat; background-size: 100%;"
        [style.background-image]="backgroundImage" [hidden]="isCamera">
      </div>
    </ng-container>
    <img *ngIf="model && !isCamera && !showButton" [src]="model"
      [ngClass]="{'avatar-uploader': isMini, 'avatar-uploader-card': !isMini}" [attr.width]="isMini? '75': '108'"
      [attr.height]="110">
    <video #videoPlayer [hidden]="!isCamera" [ngClass]="{'avatar-uploader': isMini, 'avatar-uploader-card': !isMini}"
      [attr.width]="isMini? '75':'108'" [attr.height]="75"></video>
  </nz-upload>
  <div style="margin-left: 6px;">
    <a [hidden]="isCamera" (click)="photo($event)">拍照</a>
    <a [hidden]="!isCamera" (click)="savePhoto($event)">上传</a>
  </div>
</div>

<ng-template #modalContent>
  <img *ngIf="!!previewUrl" [src]="previewUrl" [ngStyle]="{ width: '100%' }" />
</ng-template>