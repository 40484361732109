import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AreaStorageService } from '../storages/area-storage.service';
import { KeyValueParentModel } from '../models/key-value-parent.model';

@Pipe({
  name: 'areaEffective'
})
export class AreaEffectivePipe implements PipeTransform {

  constructor(private areaStorageService: AreaStorageService) { }

  transform(id?: string, exceptId?: string, includeId?: string): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.areaStorageService.getEffectiveTree(id, exceptId, includeId);
  }
}
