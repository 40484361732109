import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryStorageService } from '../storages/dictionary-storage.service';
import { of, Observable } from 'rxjs';

@Pipe({
  name: 'dictName'
})
export class DictNamePipe implements PipeTransform {

  constructor(private dictionaryStorageService: DictionaryStorageService) {

  }

  transform(value: any, args?: any): Observable<string> {
    if (!value || !args) {
      return of('');
    }

    return this.dictionaryStorageService.getName(value, args);
  }
}
