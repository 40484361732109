import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  NgZone,
  HostListener,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterContentInit,
  ViewContainerRef,
  ContentChild,
  Renderer2
} from '@angular/core';
import { SignalRService, SignalRResponse } from 'src/app/shared/notices/signalr.service';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { BreedService } from 'src/app/canine-manage/services/breed.service';
import { OwnerType, SignalRType } from 'src/app/shared/models/enum';
import { WriteCardModel } from 'src/app/canine-manage/models/write-card.model';
import { BreedDetailsModel } from 'src/app/canine-query/models/breed-details.model';
import { InternetSrcPipe } from 'src/app/shared/pipes/internet-src.pipe';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.less'],
  providers: [
    InternetSrcPipe
  ]
})
export class PrintComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('confirm', { static: true }) confirmTpl: TemplateRef<any>;
  @ViewChild('print', { read: TemplateRef, static: true }) printTpl: TemplateRef<any>;
  @ViewChild('writeCard', { static: true }) writeCardTpl: TemplateRef<any>;
  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  // 登记证号 或 电子标识码
  @Input() cardNo: string;
  // 是否显示成功页面
  @Input() isConfirm = true;
  // 成功页面提示信息
  @Input() message = '登记成功';

  @Output() callBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() successCallBack: EventEmitter<any> = new EventEmitter<any>();

  breedModel: BreedDetailsModel;

  current = 0;
  tagNo: string;
  loading = false;
  writingLoading = false;
  ownerType = OwnerType;
  // 步骤状态
  currentStepStatus = 'process';
  stepArray: Array<any>;
  ctx: { $implicit: any, [keys: string]: any } = { $implicit: null };

  private code = '';
  private lastCode: number = null;
  private nextCode: number = null;
  private printCardReceivedSubscribe: Subscription;
  private writeRegisterReceivedSubscribe: Subscription;
  private getTagNoSubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private renderer2: Renderer2,
    private datePipe: DatePipe,
    private internetSrcPipe: InternetSrcPipe,
    private messageService: NzMessageService,
    private signalRService: SignalRService,
    private breedService: BreedService
  ) { }

  ngOnInit(): void {
    this.current = 0;

    if (this.isConfirm) {
      this.stepArray = [
        {
          step: 1,
          title: '登记提醒',
          description: '',
          icon: ''
        },
        {
          step: 2,
          title: '犬证打印',
          description: '没有犬只芯片不能制卡',
          icon: ''
        }
      ];

      // 动态创建容器
      this.template = this.confirmTpl;
    } else {
      this.stepArray = [
        {
          step: 1,
          title: '犬证打印',
          description: '没有犬只芯片不能制卡',
          icon: ''
        }
      ];

      // 动态创建容器
      this.template = this.printTpl;
    }

    // 加载页面数据
    this.refreshData(this.cardNo);

    // 连接
    this.signalRService.connectionEstablished.subscribe((isConnection: boolean) => {
      if (!isConnection) {
        this.loading = false;
        this.writingLoading = false;
      }
    }, error => {
      this.loading = false;
      this.writingLoading = false;
    });

    // 打印注册
    this.printCardReceivedSubscribe = this.signalRService.printCardReceived.subscribe((message: SignalRResponse) => {
      // 执行打印业务流程
      this.ngZone.run(() => {
        // 发送打印命令
        this.loading = false;
        if (message && message.Type === SignalRType.Print) {
          // 判断是否打印成功
          if (message.Status === 1) {
            // 判断逻辑是否正常
            this.stepArray[this.current].description = null;
            this.stepArray[this.current].status = 'success';

            this.messageService.success('打印成功');
          } else {
            // 错误提示
            this.stepArray[this.current].description = message.ErrorMessage;
            this.stepArray[this.current].status = 'error';

            this.messageService.success('打印失败');
          }
        }
      });
    }, error => {
      this.loading = false;
    });

    // 写卡注册
    this.writeRegisterReceivedSubscribe = this.signalRService.writeRegisterReceived.subscribe((message: SignalRResponse) => {
      // 检测是否写卡成功，临时用isConfirm参数判断
      this.ngZone.run(() => {
        this.writingLoading = false;

        if (message && message.Type === SignalRType.Print) {
          // 判断是否写卡成功
          if (message.Status === 1) {
            // 判断逻辑是否正常
            this.stepArray[this.current].description = null;
            this.stepArray[this.current].status = 'success';

            // 关闭窗口
            this.successCallBack.emit(this.isConfirm);

            this.messageService.success('写卡成功');
          } else {
            // 判断逻辑是否正常
            this.stepArray[this.current].description = message.ErrorMessage;
            this.stepArray[this.current].status = 'error';

            this.messageService.success('写卡失败');
          }
        }
      });
    }, error => {
      this.writingLoading = false;
    });

    // 注册读取扫柄信息
    this.getTagNoSubscription = this.signalRService.getTagNoReceived.subscribe((message: SignalRResponse) => {
      this.ngZone.run(() => {
        if (message && message.Status === 1 && message.Data) {
          this.tagNo = message.Data;
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardNo && changes.cardNo.currentValue) {

    }
  }

  ngOnDestroy(): void {
    if (this.printCardReceivedSubscribe && !this.printCardReceivedSubscribe.closed) {
      this.printCardReceivedSubscribe.unsubscribe();
    }
    if (this.writeRegisterReceivedSubscribe && !this.writeRegisterReceivedSubscribe.closed) {
      this.writeRegisterReceivedSubscribe.unsubscribe();
    }
    if (this.getTagNoSubscription && !this.getTagNoSubscription.closed) {
      this.getTagNoSubscription.unsubscribe();
    }
  }

  /**
   * 设置焦点
   */
  setFocus(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const tagNoInput = document.getElementById('tagNoInput');
        if (tagNoInput) {
          tagNoInput.focus();
        }
      }, 20);
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): boolean {
    const tagNoInput = document.getElementById('tagNoInput');
    // 判断是否为芯片文本框
    if (tagNoInput && tagNoInput === event.target) {
      // 事件处理
      if (window.event) {
        this.nextCode = event.keyCode;
      } else {
        this.nextCode = event.which;
      }

      // 回车键
      if (this.nextCode === 13) {
        // 自动填充到表单
        this.tagNo = this.code;
        this.breedModel.tagNo = this.tagNo;

        this.code = '';
        this.lastCode = null;
        return false;
      }

      if ((this.nextCode >= 48 && this.nextCode <= 57) || (this.nextCode >= 96 && this.nextCode <= 105)) {
        // 数字键盘的键码值对应的字符有问题，所以手动调整键码值
        const codes = {
          48: 48, 49: 49, 50: 50, 51: 51, 52: 52, 53: 53, 54: 54, 55: 55, 56: 56, 57: 57,
          96: 48, 97: 49, 98: 50, 99: 51, 100: 52, 101: 53, 102: 54, 103: 55, 104: 56, 105: 57
        };

        this.nextCode = codes[this.nextCode];

        if (this.lastCode == null) {
          this.code = String.fromCharCode(this.nextCode);

          this.lastCode = this.nextCode;
        } else if (this.lastCode != null) {
          this.code += String.fromCharCode(this.nextCode);

          this.lastCode = this.nextCode;
        }
      } else {
        this.code = '';
        this.lastCode = null;

        // 自动填充到表单
        this.tagNo = this.code;
        this.breedModel.tagNo = this.tagNo;
      }
    }
  }

  /**
   * 电子标识码获取焦点
   * @param event 句柄
   */
  tagNoFocus(event: any): void {
    this.code = '';
    this.lastCode = null;
  }

  /**
   * 电子标识码获取焦点
   * @param event 句柄
   */
  onTagNoEnter(event: any): void {

  }

  /**
   * 清空标签项
   * @param $event 点击事件
   */
  tagNoClear($event: any): void {
    this.code = '';
    this.lastCode = null;

    this.tagNo = null;

    // 设置焦点
    this.setFocus();
  }

  /**
   * 打印事件
   */
  printClick(): void {
    this.breedModel.tagNo = this.tagNo;

    if (!this.breedModel.noseprintImg) {
      if (!this.tagNo) {
        this.messageService.error('请先扫描芯片，获取芯片号');
        return;
      }

      if (this.tagNo.length !== 15) {
        this.messageService.error('请检查获取到的芯片号码是否为15位');
        return;
      }
    }

    // 发送打印命令
    this.loading = true;
    const model: WriteCardModel = new WriteCardModel({
      // 犬登记证号码
      registrationNo: this.breedModel.registrationNo,
      // 电子标识码
      tagNo: this.tagNo,
    });

    // 更新电子标识码
    this.breedService.updateTagNo(model).subscribe((response) => {
      if (response && !response.didError) {
        // 发送写卡指令
        this.signalRService.sendPrintCard(SignalRType.Print, this.internetSrcPipe.transform(response.model));
      } else {
        this.messageService.error(response.errorMessage);
        this.loading = false;
      }
    },
      err => {
        this.loading = false;
      });
  }

  /**
   * 下一步
   */
  next(): void {
    if (this.stepArray.length - 1 > this.current) {
      this.current += 1;
      // 切换容器
      this.changeContent();
    }
  }

  /**
   * 继续登记
   */
  back(): void {
    if (this.callBack) {
      this.callBack.emit(null);
    }
  }

  /**
   * 写卡信息
   */
  writeCardClick(): void {
    // 发送打印命令
    this.writingLoading = true;

    const model: WriteCardModel = new WriteCardModel({
      // 犬登记证号码
      registrationNo: this.breedModel.registrationNo,
      // 电子标识码
      tagNo: this.tagNo,
    });

    const writeRegisterModel: any = {
      // 登记号
      registerNo: this.breedModel.registrationNo,
      // 电子标识码
      tagNo: this.tagNo,
      // 登记日期
      registerDate: this.datePipe.transform(this.breedModel.created, 'yyyy-MM-dd'),
      // 最后年检日期
      annualInspectionDate: this.datePipe.transform(this.breedModel.annualInspectionDate, 'yyyy-MM-dd')
    };

    // 更新制卡标识
    this.breedService.updateCardFlag(model).subscribe((response) => {
      if (!response.didError) {
        // 发送写卡指令
        this.signalRService.sendWriteRegister(SignalRType.Print, writeRegisterModel);
      } else {
        this.writingLoading = false;
        this.messageService.error(response.errorMessage);
      }
    },
      err => {
        this.writingLoading = false;
      });
  }

  changeContent(): void {
    if (this.isConfirm) {
      switch (this.current) {
        case 0: {
          // 提醒
          this.template = this.confirmTpl;
          break;
        }
        case 1: {
          // 打印
          this.template = this.printTpl;

          setTimeout(() => {
            // 设置焦点
            this.setFocus();
          }, 100);
          break;
        }
        case 2: {
          // 写卡
          this.template = this.writeCardTpl;
          break;
        }
        default: {
        }
      }
    } else {
      switch (this.current) {
        case 0: {
          // 打印
          this.template = this.printTpl;

          setTimeout(() => {
            // 设置焦点
            this.setFocus();
          }, 100);
          break;
        }
        case 1: {
          // 写卡
          this.template = this.writeCardTpl;
          break;
        }
        default: {
        }
      }
    }
  }

  /**
   * 加载数据
   * @param cardNo 养犬登记证号
   */
  refreshData(cardNo: string): void {
    this.breedService.details(cardNo).subscribe(
      response => {
        // 判断是否成功
        if (!response.didError) {
          if (response.model) {
            this.breedModel = response.model;
            // 电子标识码初始化
            this.tagNo = this.breedModel ? this.breedModel.tagNo : null;
          }
        }
      }
    );
  }
}
