<form nz-form [formGroup]="validateForm">
    <div nz-row>
        <div nz-col nzSm="24" nzMd="12" nzXl="8">
            <nz-form-item nzJustify="start" nzAlign="top">
                <nz-form-label class="form-label-100" nzRequired>免疫编号</nz-form-label>
                <nz-form-control class="form-control-1" [nzErrorTip]="errorImmunityNo">
                    <input nz-input formControlName="immunityNo" placeholder="免疫编号" trim>
                    <ng-template #errorImmunityNo let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            请输入免疫编号!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            最多可输入30个字符!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="8">
            <nz-form-item nzJustify="start" nzAlign="top">
                <nz-form-label class="form-label-100" nzRequired>免疫日期</nz-form-label>
                <nz-form-control class="form-control-1" [nzErrorTip]="errorImmunityDate">
                    <nz-date-picker formControlName="immunityDate" [nzDisabledDate]="immunityDateDisabled"
                        [nzShowToday]="false" placeholder="免疫日期">
                    </nz-date-picker>
                    <ng-template #errorImmunityDate let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            请输入免疫日期！
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="8">
            <nz-form-item nzJustify="start" nzAlign="top">
                <nz-form-label class="form-label-110" nzRequired>下次年检日期</nz-form-label>
                <nz-form-control class="form-control-1" [nzErrorTip]="errorNextAnnualInspectionDate">
                    <nz-date-picker formControlName="nextAnnualInspectionDate" [nzShowToday]="false"
                        placeholder="下次年检日期">
                    </nz-date-picker>
                    <ng-template #errorNextAnnualInspectionDate let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            请输入下次年检日期！
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>