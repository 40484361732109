import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { of, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';

import { BreedDetailsModel } from 'src/app/canine-query/models/breed-details.model';
import { ImgSrcPipe } from 'src/app/shared/pipes/img-src.pipe';
import { LimitedAreaStrategyStorageService } from 'src/app/shared/storages/limited-area-strategy-storage.service';
import { DictionaryStorageService } from 'src/app/shared/storages/dictionary-storage.service';

@Component({
  selector: 'app-pet-details',
  templateUrl: './pet-details.component.html',
  styleUrls: ['./pet-details.component.less'],
  providers: [
    ImgSrcPipe
  ]
})
export class PetDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() model?: BreedDetailsModel;

  /**
   * 一般限养区敏感词
   */
  private generalSensitive = false;
  /**
   * 重点限养区铭感词
   */
  private keepSensitive = false;

  private previewImgModal: NzModalRef<any>;
  previewUrl: string;
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: false,
    hidePreviewIconInNonImage: true
  };

  dogOwnerPhotoUrl = [];
  frontPhotoUrl = [];
  sidePhotoUrl = [];
  noseprintImg = [];

  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private imgSrcPipe: ImgSrcPipe,
    private dictionaryStorageService: DictionaryStorageService,
    private limitedAreaStrategyStorageService: LimitedAreaStrategyStorageService,
  ) {
  }

  ngOnInit(): void {

    if (this.model) {
      // 限养区
      this.onAreaChanges(this.model?.areaCodes);
    }
  }

  /**
   * 变更钩子
   * @param changes 变更
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      this.dogOwnerPhotoUrl = [{
        url: this.imgSrcPipe.transform(this.model.petAndOwnerImg)
      }];

      this.frontPhotoUrl = [{
        url: this.imgSrcPipe.transform(this.model.petFrontImg)
      }];

      this.sidePhotoUrl = [{
        url: this.imgSrcPipe.transform(this.model.petSideImg)
      }];

      if (this.model.noseprintImg) {
        let noseprintImg = [];
        this.model.noseprintImg?.split(',')?.forEach(item => {
          noseprintImg.push({ url: this.imgSrcPipe.transform(this.model.noseprintImg) });
        });

        this.noseprintImg = noseprintImg;
      }

      this.onAreaChanges(changes.model.currentValue?.areaCodes);
    }
  }

  ngOnDestroy(): void {
    // 模态窗口回收
    if (this.previewImgModal) {
      this.previewImgModal.destroy();
    }
  }

  /**
   * 辖区选中改变事件
   * @param $event 当前选中区域
   */
  public onAreaChanges(areaCodes: Array<string>): any {
    if (areaCodes && areaCodes.length > 0) {
      const areaId = areaCodes[areaCodes.length - 1];
      this.limitedAreaStrategyStorageService.getAreaType(areaId).subscribe(
        (areaType: number) => {
          const limited$ = forkJoin([
            this.dictionaryStorageService.getEffectiveItems('GeneralSensitive'),
            this.dictionaryStorageService.getEffectiveItems('KeepSensitive')
          ]);
          limited$.pipe(
            mergeMap((value) => {
              if (value) {
                return of(value);
              } else {
                return of(null);
              }
            })
          ).subscribe((response) => {
            if (response) {
              const oldAreaType = this.model.areaType;
              const address = this.model?.address;
              const generalSensitiveStorage = response[0];
              const keepSensitiveStorage = response[1];

              // 一般限养区
              this.generalSensitive = generalSensitiveStorage.some(x => address.includes(x.key));
              // 重点限养区
              this.keepSensitive = keepSensitiveStorage.some(x => address.includes(x.key));
              // 判断是否存在重点限养区敏感词
              if (this.keepSensitive) {
                // 限养区策略
                areaType = 2;
              } else if (this.generalSensitive) { // 判断是否存在一般限养区敏感词
                // 限养区策略
                areaType = 1;
              }
              if (areaType) {
                this.model.areaType = areaType;
              }
              if (areaType !== oldAreaType) {
                this.model.oldAreaType = oldAreaType;
              }
            }
          }, error => {
            this.message.error('服务异常!');
          });
        }
      );
    }
  }

  /**
   * 查看图片
   * @param url 图片地址
   */
  showImage(url: string): void {
    this.previewUrl = this.imgSrcPipe.transform(url);
    this.createPreviewImgModal();
  }

  createPreviewImgModal(title?: string): void {
    this.previewImgModal = this.modalService.create({
      nzTitle: title ? title : '图片预览',
      nzContent: this.modalContent,
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzOnOk: () => {
        // Ok按钮
      }
    });
  }

  reset(): void {
    // 重置
    this.model = null;
    this.previewUrl = null;
    this.dogOwnerPhotoUrl = [];
    this.frontPhotoUrl = [];
    this.sidePhotoUrl = [];
    this.noseprintImg = [];
  }
}
