import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ChargeStrategyModel } from '../../system-manage/models/charge-strategy.model';
import { ChargeStrategyService } from '../../system-manage/services/charge-strategy.service';
import { Observable, of } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class ChargeStrategyStorageService {

  private readonly DICKEY: string = 'CHARGE_STRATEGY';
  private http$: Observable<ListResponse<ChargeStrategyModel>>;

  constructor(
    private localStorageService: LocalStorageService,
    private chargeStrategyService: ChargeStrategyService
  ) { }

  /**
   * 收费策略缓存初始化
   */
  public setStorage(): Observable<ListResponse<ChargeStrategyModel>> {
    if (!this.http$) {
      this.http$ = this.chargeStrategyService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<ChargeStrategyModel>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取收费策略缓存
   */
  public getStorage(): Observable<Array<ChargeStrategyModel>> {

    const result = this.localStorageService.get<Array<ChargeStrategyModel>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map((value: ListResponse<ChargeStrategyModel>, index) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    return of(result);
  }

  /**
   * 获取有效收费策略缓存
   */
  public getEffectiveStorage(): Observable<Array<ChargeStrategyModel>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据费用策略获取费用金额
   * @param chargeItem 收费项
   * @param isDisabled 是否残疾
   * @param areaType 限养区类型
   * @param isNeutered 是否绝育
   */
  public getChargeAmount(chargeItem: number, isDisabled: number, areaType: number, isNeutered: number): Observable<number> {
    let result = 0;

    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<ChargeStrategyModel>, index: number) => {
        if (!value) {
          return result;
        }

        const item = value.find(x =>
          +x.chargeItem === +chargeItem
          && +x.isDisabled === +isDisabled
          && +x.areaType === +areaType
          && +x.isNeutered === +isNeutered);

        result = item ? item.amount : 0;

        return result;
      })
    );
  }

  /**
   * 根据费用策略获取费用金额
   * @param chargeItem 收费项 1：登记；2：年检
   * @param isDisabled 是否残疾
   * @param areaType 限养区类型
   * @param isNeutered 是否绝育
   */
  public getChargeStrategy(chargeItem: number, isDisabled: number, areaType: number, isNeutered: number)
    : Observable<ChargeStrategyModel> {

    let result = new ChargeStrategyModel({ amount: 0, chargeType: 0 });

    // 获取字典项
    return this.getStorage().pipe(
      map(
        (value: Array<ChargeStrategyModel>, index: number) => {
          if (!value) {
            return result;
          }

          const item = value.find(x =>
            +x.chargeItem === +chargeItem
            && +x.isDisabled === +isDisabled
            && +x.areaType === +areaType
            && +x.isNeutered === +isNeutered);

          result = item ? item : new ChargeStrategyModel({ amount: 0, chargeType: 0 });

          return result;
        }
      )
    );
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
