// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  JWTToken: {
    client_id: 'angular.pets',
    client_secret: 'angular.P@ssw0rd_1@3$5^',
    grant_type: 3,
  },
  SERVER: {
    // tslint:disable-next-line:max-line-length
    URL: 'https://petsapi.sxzbxx.com', // 'http://localhost:5677' , 'https://petsapi.sxzbxx.com'
    DEVICE: 'http://127.0.0.1:8010', // 'http://127.0.0.1:8010'  , 'https://127.0.0.1:8011'
  },
  RABBITMQ: {
    protocol: 'wss',
    host: 'pets.sxzbxx.com', // '62.234.123.30', '10.173.1.247',
    port: 15673,
    path: 'ws',
    user: 'admin',
    password: 'admin',
  },
  MAP: {
    // 高德地图
    AMAP: 'd4a1235cb3ae825be5b4397cdfe0330c',
    AMAPSecret: '8027cfc88e2661bac510514fe4acf0ec'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
