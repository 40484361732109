import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../shared/base.service';
import { ListResponse } from '../../shared/models/list-response';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PagedResponse } from '../../shared/models/paged-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';
import { ChargeStrategyModel } from '../models/charge-strategy.model';
import { ChargeStrategyParameter } from '../models/charge-strategy-parameter';
import { ChargeStrategySearchModel } from '../models/charge-strategy-search.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeStrategyService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/chargeStrategy`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有费用策略列表
   */
  getAll(): Observable<ListResponse<ChargeStrategySearchModel>> {
    const url = `${this.apiUrl}/getAll`;
    return this.http.get<ListResponse<ChargeStrategySearchModel>>(url);
  }

  /**
   * 获取收费策略分页集合
   * @param chargingStrategyParameter 查询参数
   */
  queryPageList(parameter: ChargeStrategyParameter)
    : Observable<PagedResponse<ChargeStrategySearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<ChargeStrategySearchModel>>(this.apiUrl, { params })
      .pipe(
        tap(
          (response: PagedResponse<ChargeStrategySearchModel>) => {
          }
        )
      );
  }

  /**
   * 获取收费策略详情
   * @param id 收费策略ID
   */
  get(id: string): Observable<SingleResponse<ChargeStrategyModel>> {

    const url = `${this.apiUrl}/${id}`;

    return this.http.get<SingleResponse<ChargeStrategyModel>>(url);
  }

  /**
   * 新增收费策略
   * @param chargingStrategy 收费策略实体
   */
  add(chargingStrategy: ChargeStrategyModel): Observable<SingleResponse<ChargeStrategyModel>> {
    return this.http.post<SingleResponse<ChargeStrategyModel>>(this.apiUrl, chargingStrategy, this.httpOptions);
  }

  /**
   * 更新收费策略
   * @param chargingStrategy 收费策略
   */
  update(chargingStrategy: ChargeStrategyModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${chargingStrategy.id}`;

    return this.http.put<NocontentResponse>(url, chargingStrategy, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除收费策略
   * @param id 收费策略ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
