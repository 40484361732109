import { Pipe, PipeTransform } from '@angular/core';
import { AreaStorageService } from '../storages/area-storage.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'areaName'
})
export class AreaNamePipe implements PipeTransform {

  constructor(private areaStorageService: AreaStorageService) { }

  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.areaStorageService.getName(value);
  }
}
