import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BreedDetailsModel } from 'src/app/canine-query/models/breed-details.model';

@Component({
  selector: 'app-inspection-edit',
  templateUrl: './inspection-edit.component.html',
  styleUrls: ['./inspection-edit.component.less']
})
export class InspectionEditComponent implements OnInit, OnChanges {
  // 免疫日期
  @Input() immunityDate: Date;
  // 年检日期
  @Input() annualInspectionDate: Date;
  // 下次年检日期
  @Input() needAnnualInspectionDate: Date;
  // 犬只登记详情
  @Input() breedModel: BreedDetailsModel;

  // 下次年检日期
  public nextAnnualInspectionDate: Date;

  public validateForm: FormGroup;

  get valid(): boolean {
    if (!this.validateForm.valid) {
      return false;
    }

    return true;
  }

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
    // 表单初始化
    this.validateForm = this.fb.group({
      // 免疫编号
      immunityNo: [null, [Validators.required, Validators.maxLength(30)]],
      // 免疫日期
      immunityDate: [null, [Validators.required]],
      // 下次年检日期
      nextAnnualInspectionDate: [null, [Validators.required]]
    });

    this.defaultAnnualInspectionDate(this.annualInspectionDate);
    this.defaultNextAnnualInspectionDate(this.needAnnualInspectionDate);
  }

  /**
   * 变更实现
   * @param changes 变更
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.needAnnualInspectionDate && changes.needAnnualInspectionDate.currentValue) {
      this.defaultNextAnnualInspectionDate(this.needAnnualInspectionDate);
    } else {
      if (changes.immunityDate && changes.immunityDate.currentValue) {
        this.defaultImmunityDate(this.immunityDate);
      }
      if (changes.annualInspectionDate && changes.annualInspectionDate.currentValue) {
        this.defaultAnnualInspectionDate(this.annualInspectionDate);
      }
    }

    if (changes.breedModel && changes.breedModel.currentValue) {
      const model = changes.breedModel.currentValue;
      if (this.validateForm && model) {
        this.applyAnnualInit(changes.breedModel.currentValue);
      }
    }
  }

  private applyAnnualInit(model: any): void {
    this.validateForm.patchValue({
      // 免疫编号
      immunityNo: model.immunityNo,
      // 免疫日期
      immunityDate: model.immunityDate
    });
  }

  /**
   * 默认免疫日期
   * @param annualInspectionDate 免疫日期
   */
  defaultImmunityDate(annualInspectionDate: Date): void {
    try {
      if (annualInspectionDate) {
        const date = new Date(this.annualInspectionDate);

        this.validateForm.patchValue({
          // 下次年检日期
          nextAnnualInspectionDate: new Date(date.setFullYear(date.getFullYear() + 1))
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  /**
   * 默认年检日期
   * @param annualInspectionDate 年检日期
   */
  defaultAnnualInspectionDate(annualInspectionDate: Date): void {
    try {
      if (annualInspectionDate) {
        const date = new Date(this.annualInspectionDate);

        this.validateForm.patchValue({
          // 下次年检日期
          nextAnnualInspectionDate: new Date(date.setFullYear(date.getFullYear() + 1))
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  /**
   * 默认下次年检日期
   * @param needAnnualInspectionDate 年检日期
   */
  defaultNextAnnualInspectionDate(needAnnualInspectionDate: Date): void {
    try {
      if (needAnnualInspectionDate) {
        const date = new Date(this.needAnnualInspectionDate);

        this.validateForm.patchValue({
          // 下次年检日期
          nextAnnualInspectionDate: date
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  /**
   * 年检信息预提交
   */
  public preSubmit(): any {
    // 初始化数据
    let annualInspection = null;
    const formData = this.validateForm.getRawValue();

    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls[key]) {
        const control: AbstractControl = this.validateForm.controls[key];
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    }

    if (!this.validateForm.valid) {
      return annualInspection;
    }

    annualInspection = {};

    // 免疫编号
    annualInspection.immunityNo = formData.immunityNo;
    // 免疫日期
    annualInspection.immunityDate = formData.immunityDate;
    // 年检日期
    annualInspection.annualInspectionDate = this.annualInspectionDate;
    // 下次年检日期
    annualInspection.nextAnnualInspectionDate = formData.nextAnnualInspectionDate;

    return annualInspection;
  }

  immunityDateDisabled = (current: Date) => {
    const today = new Date();
    return current > today;
  }

  /**
   * 重置表单
   * @param e 鼠标事件
   */
  public reset(): void {
    this.validateForm.reset();

    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }
}
