import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base.service';
import { Observable } from 'rxjs';
import { ListResponse } from '../../shared/models/list-response';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';
import { ProhibitionStrategyModel } from '../models/prohibition-strategy.model';
import { ProhibitionStrategySearchModel } from '../models/prohibition-strategy-search.model';

@Injectable({
  providedIn: 'root'
})
export class ProhibitionStrategyService extends BaseService {
  // URL to web api
  private prohibitionStrategyUrl = `${this.apiUrlBase}/api/prohibitionstrategy`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有禁养策略列表
   */
  getAll(): Observable<ListResponse<ProhibitionStrategySearchModel>> {
    const url = `${this.prohibitionStrategyUrl}/getall`;
    return this.http.get<ListResponse<ProhibitionStrategySearchModel>>(url);
  }

  /**
   * 批量新增禁养策略
   * @param areaType 限养区类型
   * @param prohibitionStrategies 禁养策略集合
   */
  update(areaType: number, prohibitionStrategies: Array<ProhibitionStrategyModel>): Observable<SingleResponse<ProhibitionStrategyModel>> {

    const url = `${this.prohibitionStrategyUrl}/${areaType}`;

    return this.http
      .put<SingleResponse<ProhibitionStrategyModel>>(url, prohibitionStrategies, this.httpOptions)
      .pipe(
        tap(
          (response: SingleResponse<ProhibitionStrategyModel>) => {
          }
        )
      );
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.prohibitionStrategyUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除禁养策略
   * @param id 禁养策略ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.prohibitionStrategyUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
