import { Deleted } from './enum';

/**
 * 分页查询参数
 */
export class PagedParameters {
  public pageIndex?: number;
  public pageSize?: number;
  public dataStatus?: number;
  public fields?: string;
  public orderBy?: string;

  constructor(init?: Partial<PagedParameters>) {
    // 判断对象是否存在
    if (!init || !init.pageIndex) {
      // 索引初始化
      this.pageIndex = 1;
    }
    // 判断对象是否存在
    if (!init || !init.pageSize) {
      // 分页大小初始化
      this.pageSize = 15;
    }
    // 数据状态
    if (!init || !init.dataStatus) {
      // 数据状态
      this.dataStatus = Deleted.Normal;
    }

    Object.assign(this, init);
  }

  /**
   * 重置
   */
  reset(init?: Partial<PagedParameters>): void {
    Object.assign(this, init);
  }
}
