import { Injectable } from '@angular/core';
import { UserResourceStorageService } from './user-resource-storage.service';
import { DictionaryStorageService } from './dictionary-storage.service';
import { PetKindStorageService } from './pet-kind-storage.service';
import { NationalStorageService } from './national-storage.service';
import { AreaStorageService } from './area-storage.service';
import { OrganizationStorageService } from './organization-storage.service';
import { ChargeStrategyStorageService } from './charge-strategy-storage.service';
import { LimitedAreaStrategyStorageService } from './limited-area-strategy-storage.service';
import { ProhibitionStrategyStorageService } from './prohibition-strategy-storage.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexStorageService {

  constructor(
    private userResourceStorageService: UserResourceStorageService,
    private dictionaryStorageService: DictionaryStorageService,
    private petKindStorageService: PetKindStorageService,
    private nationalStorageService: NationalStorageService,
    private areaStorageService: AreaStorageService,
    private organizationStorageService: OrganizationStorageService,
    private chargeStrategyStorageService: ChargeStrategyStorageService,
    private limitedAreaStrategyStorageService: LimitedAreaStrategyStorageService,
    private prohibitionStrategyStorageService: ProhibitionStrategyStorageService
  ) { }

  /**
   * 初始化数据
   */
  initStorage(): Observable<any> {
    return forkJoin(
      // 用户资源
      this.userResourceStorageService.setStorage(),
      // 字典
      this.dictionaryStorageService.setStorage(),
      // 犬种
      this.petKindStorageService.setStorage(),
      // 民族
      this.nationalStorageService.setStorage(),
      // 辖区
      this.areaStorageService.setStorage(),
      // 机构
      this.organizationStorageService.setStorage(),
      // 收费策略
      this.chargeStrategyStorageService.setStorage(),
      // 限养策略
      this.limitedAreaStrategyStorageService.setStorage(),
      // 禁养
      this.prohibitionStrategyStorageService.setStorage()
    );
  }

  clearStorage(): void {
    // 清空用户资源
    this.userResourceStorageService.clearStorage();
    // 字典
    this.dictionaryStorageService.clearStorage();
    // 犬种
    this.petKindStorageService.clearStorage();
    // 民族
    this.nationalStorageService.clearStorage();
    // 辖区
    this.areaStorageService.clearStorage();
    // 机构
    this.organizationStorageService.clearStorage();
    // 收费策略
    this.chargeStrategyStorageService.clearStorage();
    // 限养策略
    this.limitedAreaStrategyStorageService.clearStorage();
    // 禁养
    this.prohibitionStrategyStorageService.clearStorage();
  }
}
