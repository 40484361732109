import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from '../../frame/services/auth.service';
import { UserResourceStorageService } from '../storages/user-resource-storage.service';
import { Observable } from 'rxjs';
import { MenuSearchModel } from '../../system-manage/models/menu-search.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

    private whiteList: Array<string> = ['/not-found', '/exception/no-permision'];

    constructor(
        private router: Router,
        private authService: AuthService,
        private userResourceStorageService: UserResourceStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        // 权限
        const auth = this.authService.authValue;
        // url
        const url = state.url;

        return this.isAuth(auth, url);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // 权限
        const auth = this.authService.authValue;
        // url
        const url = state.url;

        return this.isAuth(auth, url);
    }

    /**
     * 校验是否授权通过
     * @param auth 授权信息
     * @param url 跳转地址
     */
    private isAuth(auth: any, url: string): Observable<boolean> {
        return this.userResourceStorageService.getMenu().pipe(
            map(
                (menus: Array<MenuSearchModel>, index: number) => {
                    if (menus) {
                        const menu = menus.find(x => x.router === url);

                        if (!menu && !this.whiteList.includes(url)) {
                            // 没有权限访问
                            this.router.navigate([this.whiteList[1]]);

                            return false;
                        }
                    }

                    if (auth) {
                        return true;
                    }

                    // not logged in so redirect to login page with the return url
                    this.router.navigate(['/login'], { queryParams: { returnUrl: url } });

                    return false;
                }
            ));
    }
}
