import { PagedParameters } from '../../shared/models/paged-parameters';

/**
 * 养犬历史查询参数
 */
export class BreedHistoryListParameter extends PagedParameters {
    /**
     * 登记号
     */
    registrationNo?: string;
    /**
     * 业务类型
     */
    businessType?: number;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init?: Partial<BreedHistoryListParameter>) {
        super();

        Object.assign(this, init);
    }

    /**
     * 初始化数据
     * @param init 初始化
     */
    init(init?: Partial<BreedHistoryListParameter>): void {
        Object.assign(this, init);
    }
}
