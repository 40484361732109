import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LocalStorageModule } from 'angular-2-local-storage';

import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { ToinkRxStompConfig } from './toink-rx-stomp.config';

import { AppComponent } from './app.component';
import { FrameModule } from './frame/frame.module';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    LocalStorageModule.forRoot({
      prefix: 'pets-app',
      storageType: 'localStorage'
    }),
    // customer
    FrameModule
  ],
  providers: [
    DatePipe,
    { provide: InjectableRxStompConfig, useValue: ToinkRxStompConfig },
    { provide: RxStompService, useFactory: rxStompServiceFactory, deps: [InjectableRxStompConfig] }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
