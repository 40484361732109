/**
 * 制证写卡
 */
export class WriteCardModel {

    /**
     * 登记号码
     */
    public registrationNo: string;

    /**
     * 电子标识码
     */
    public tagNo: string;

    /**
     * 犬主类型
     */
    public ownerType: number;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init?: Partial<WriteCardModel>) {
        Object.assign(this, init);
    }
}
