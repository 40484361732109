import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { KeyValue } from '@angular/common';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { DictionaryService } from '../../system-manage/services/dictionary.service';
import { ListResponse } from '../models/list-response';
import { tap, map, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Deleted } from '../models/enum';

export interface DictionaryInterface {
  code: string;

  name: string;

  categoryCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class DictionaryStorageService {

  private readonly DICKEY: string = 'DIC_KEY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private dictionaryService: DictionaryService
  ) { }

  /**
   * 字典项初始化
   */
  public setStorage(): Observable<ListResponse<KeyValueParentModel<string, number, string>>> {
    if (!this.http$) {
      this.http$ = this.dictionaryService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<KeyValueParentModel<string, number, string>>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取字典项缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, number, string>>> {

    const result = this.localStorageService.get<Array<KeyValueParentModel<string, number, string>>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map((value, index) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    return of(result);
  }

  /**
   * 获取有效字典项缓存
   */
  public getEffectiveStorage(): Observable<Array<KeyValueParentModel<string, number, string>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据字典分类编码获取分类下的字典项
   * @param categoryCode 字典分类编码
   */
  public getItems(categoryCode: string): Observable<Array<KeyValue<string, number>>> {

    if (!categoryCode) {
      return of([]);
    }

    const result = this.getStorage().pipe(
      map(
        (value: Array<KeyValueParentModel<string, number, string>>, index) => {
          const items = value.filter(x => x.parent === categoryCode)
            .map(x => ({ key: x.key, value: Number(x.value) }))
            .sort((a, b) => a.value - b.value);

          return items;
        })
    );

    return result;
  }

  /**
   * 根据字典分类编码获取分类下有效字典项
   * @param categoryCode 字典分类编码
   * @param include 是否包含特定项
   */
  public getEffectiveItems(categoryCode: string, include?: number): Observable<Array<KeyValue<string, number>>> {
    let result: Observable<Array<KeyValue<string, number>>> = of([]);
    if (!categoryCode) {
      return result;
    }

    // 判断是否需要包含特定项
    if (include) {
      result = this.getStorage().pipe(
        map(
          (value: Array<KeyValueParentModel<string, number, string>>, index) => {
            const items = value.filter(x => x.parent === categoryCode && (x.dataStatus !== Deleted.Deleted || x.value === include))
              .map(x => ({ key: x.key, value: Number(x.value) }));

            return items;
          })
      );
    } else {
      result = this.getEffectiveStorage().pipe(
        map(
          (value: Array<KeyValueParentModel<string, number, string>>, index) => {
            const items = value.filter(x => x.parent === categoryCode)
              .map(x => ({ key: x.key, value: Number(x.value) }));

            return items;
          })
      );
    }

    return result;
  }

  /**
   * 根据字典编码及字典分类编码获取字典名称
   * @param dictionaryCode 字典编码
   * @param categoryCode 字典分类编码
   */
  public getItem(dictionaryCode: number, categoryCode: string): Observable<KeyValueParentModel<string, number, string>> {

    if (!dictionaryCode || !categoryCode) {
      return of(null);
    }

    // 获取字典项
    return this.getStorage().pipe(
      map((value, index) => {
        const result = value.find(x => x.value === dictionaryCode && x.parent === categoryCode);

        return result;
      })
    );
  }

  /**
   * 根据字典编码及字典分类编码获取字典名称
   * @param dictionaryCode 字典编码
   * @param categoryCode 字典分类编码
   */
  public getName(dictionaryCode: number, categoryCode: string): Observable<string> {

    if (!dictionaryCode || !categoryCode) {
      return of('');
    }

    // 获取字典项
    return this.getStorage().pipe(
      map((value, index) => {
        const item = value.find(x => x.value === dictionaryCode && x.parent === categoryCode);
        // 解析数据
        const result = item ? item.key : '';

        return result;
      })
    );
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
