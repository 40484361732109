import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictPipe } from './dict.pipe';
import { DictNamePipe } from './dict-name.pipe';
import { PetKindNamePipe } from './pet-kind-name.pipe';
import { NationalNamePipe } from './national-name.pipe';
import { AreaNamePipe } from './area-name.pipe';
import { OrganizationNamePipe } from './organization-name.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NationalPipe } from './national.pipe';
import { DictEffectivePipe } from './dict-effective.pipe';
import { NationalEffectivePipe } from './national-effective.pipe';
import { AreaEffectivePipe } from './area-effective.pipe';
import { OrganizationEffectivePipe } from './organization-effective.pipe';
import { PetKindEffectivePipe } from './pet-kind-effective.pipe';
import { DateLimitPipe } from './date-limit.pipe';
import { UtcOffsetPipe } from './utc-offset.pipe';
import { InternetSrcPipe } from './internet-src.pipe';
import { RelativeSrcPipe } from './relative-src.pipe';
import { DayWeekPipe } from './day-week.pipe';
import { ImgSrcPipe } from './img-src.pipe';

@NgModule({
  declarations: [
    DictPipe,
    DictNamePipe,
    PetKindNamePipe,
    NationalNamePipe,
    AreaNamePipe,
    OrganizationNamePipe,
    SafeHtmlPipe,
    NationalPipe,
    DictEffectivePipe,
    NationalEffectivePipe,
    AreaEffectivePipe,
    OrganizationEffectivePipe,
    PetKindEffectivePipe,
    DateLimitPipe,
    UtcOffsetPipe,
    InternetSrcPipe,
    RelativeSrcPipe,
    DayWeekPipe,
    ImgSrcPipe,
  ],
  imports: [
    CommonModule
  ],
  providers: [
  ],
  exports: [
    DictPipe,
    DictNamePipe,
    PetKindNamePipe,
    NationalNamePipe,
    AreaNamePipe,
    OrganizationNamePipe,
    SafeHtmlPipe,
    NationalPipe,
    DictEffectivePipe,
    NationalEffectivePipe,
    AreaEffectivePipe,
    OrganizationEffectivePipe,
    PetKindEffectivePipe,
    DateLimitPipe,
    InternetSrcPipe,
    RelativeSrcPipe,
    DayWeekPipe,
    ImgSrcPipe,
  ]
})
export class PipesModule {
  static forRoot(): any {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
