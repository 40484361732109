import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { NationalService } from '../../system-manage/services/national.service';
import { Observable, of } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { KeyValueModel } from '../models/key-value.model';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class NationalStorageService {

  private readonly DICKEY: string = 'NATIONAL_KEY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private nationalService: NationalService
  ) { }

  /**
   * 民族项初始化
   */
  public setStorage(): Observable<ListResponse<KeyValueModel<string, string>>> {
    if (!this.http$) {
      this.http$ = this.nationalService.getNationalKeyValue().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<KeyValueModel<string, string>>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取民族缓存
   */
  public getStorage(): Observable<Array<KeyValueModel<string, string>>> {

    const result = this.localStorageService.get<Array<KeyValueModel<string, string>>>(this.DICKEY);

    if (!result) {
      // 重新拉取数据
      return this.setStorage().pipe(
        map((value, index) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    return of(result);
  }

  /**
   * 获取有效民族缓存
   * @param include 包括的民族
   */
  public getEffectiveStorage(include?: string): Observable<Array<KeyValueModel<string, string>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted || (include && include === x.value));
      })
    );
  }

  /**
   * 根据民族ID获取民族名称
   * @param id 民族ID
   */
  public getName(id: string): Observable<string> {
    let result = '';

    if (!id) {
      return of(result);
    }

    // 获取民族项
    return this.getStorage().pipe(
      map((value, index) => {
        const item = value.find(x => x.value === id);
        result = item ? item.key : '';
        return result;
      })
    );
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
