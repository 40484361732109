import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SignalRType } from '../models/enum';

// declare the global variables
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  // 服务地址
  protected deviceUrl = environment.SERVER.DEVICE;

  // Declare the variables
  private proxy: any;
  private proxyName = 'MessageHub';
  private connection: any;

  // create the Event Emitter
  public messageReceived: EventEmitter<any>;
  public connectionEstablished: EventEmitter<boolean>;
  public connectionExists: boolean;

  public printCardReceived: EventEmitter<SignalRResponse> = new EventEmitter<SignalRResponse>();
  public writeRegisterReceived: EventEmitter<SignalRResponse> = new EventEmitter<SignalRResponse>();
  public getRegisterReceived: EventEmitter<SignalRResponse> = new EventEmitter<SignalRResponse>();
  public getTagNoReceived: EventEmitter<SignalRResponse> = new EventEmitter<SignalRResponse>();

  constructor(private message: NzMessageService) {
    // Constructor initialization
    this.connectionEstablished = new EventEmitter<boolean>();
    this.messageReceived = new EventEmitter<any>();

    this.connectionExists = false;

    // create hub connection
    this.connection = $.hubConnection(this.deviceUrl);

    // create new proxy as name already given in top
    this.proxy = this.connection.createHubProxy(this.proxyName);

    // register on server events
    this.registerOnServerEvents();

    // call the connecion start method to start the connection to send and receive events.
    this.startConnection();

    // 监听是否断开连接
    this.connection.error(() => {
      this.connectionEstablished.emit(false);
      this.message.error('打印服务调用失败');
    });

    // 获取从服务端发送过来的未处理异常信息
    this.proxy.onGlobalServerError = (errMsg) => {
      this.connectionEstablished.emit(false);

      this.message.error('设备通讯失败');
    };
  }

  // check in the browser console for either signalr connected or not
  private startConnection(callback?: () => void): void {
    this.connection
      .start()
      .done((data: any) => {
        this.connectionEstablished.emit(true);
        this.connectionExists = true;
        // 判断是否存在回调函数
        if (callback) {
          callback();
        }
      }).fail((error: any) => {
        // console.log('Could not connect ' + error);
        // this.message.error('打印服务调用失败');

        this.connectionEstablished.emit(false);
        this.connectionExists = false;
      });
  }

  // method to hit from client
  public sendTime(): void {
    if (this.connection.state !== 1) {
      this.startConnection(() => {
        // server side hub method using proxy.invoke with method name pass as param
        this.proxy.invoke('GetRealTime');
      });
    } else {
      // server side hub method using proxy.invoke with method name pass as param
      this.proxy.invoke('GetRealTime');
    }
  }

  /**
   * 打印
   * @param type 业务类型
   * @param url 电子犬证图片路径
   */
  public sendPrintCard(type: SignalRType, url: string): void {
    if (this.connection.state !== 1) {
      this.startConnection(() => {
        // server side hub method using proxy.invoke with method name pass as param
        this.proxy.invoke('PrintCard', type, url);
      });
    } else {
      // server side hub method using proxy.invoke with method name pass as param
      this.proxy.invoke('PrintCard', type, url);
    }
  }

  /**
   * 写卡
   * @param type 业务类型
   * @param data 登记信息
   */
  public sendWriteRegister(type: SignalRType, data: { [key: string]: any }): void {
    if (this.connection.state !== 1) {
      this.startConnection(() => {
        // server side hub method using proxy.invoke with method name pass as param
        this.proxy.invoke('WriteRegisterInfo', type, data);
      });
    } else {
      // server side hub method using proxy.invoke with method name pass as param
      this.proxy.invoke('WriteRegisterInfo', type, data);
    }
  }

  /**
   * 读卡
   * @param type 业务类型
   */
  public sendGetRegister(type: SignalRType): void {
    if (this.connection.state !== 1) {
      this.startConnection(() => {
        // server side hub method using proxy.invoke with method name pass as param
        this.proxy.invoke('GetRegisterNo', type);
      });
    } else {
      // server side hub method using proxy.invoke with method name pass as param
      this.proxy.invoke('GetRegisterNo', type);
    }
  }

  /**
   * 接收消息
   */
  private registerOnServerEvents(): void {
    // 获取实时消息
    this.proxy.on('setRealTime', (data: any) => {
      this.messageReceived.emit(data);
    });

    // 打印
    this.proxy.on('PrintCard', (data: SignalRResponse) => {
      this.printCardReceived.emit(data);
    });

    // 写卡
    this.proxy.on('WriteRegisterInfo', (data: SignalRResponse) => {
      this.writeRegisterReceived.emit(data);
    });

    // 读卡
    this.proxy.on('GetRegisterNo', (data: SignalRResponse) => {
      this.getRegisterReceived.emit(data);
    });

    // 读取电子标识码
    this.proxy.on('GetTagNo', (data: SignalRResponse) => {
      this.getTagNoReceived.emit(data);
    });
  }
}

export interface SignalRResponse {
  /**
   * 请求状态
   */
  Status: number;
  /**
   * 业务类型
   */
  Type: SignalRType;
  /**
   * 数据域
   */
  Data: any;
  /**
   * 处理消息
   */
  Message: string;
  /**
   * 异常消息
   */
  ErrorMessage: string;
}
