import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { AreaModel } from '../models/area.model';
import { AreaParameter } from '../models/area-parameter';
import { AreaSearchModel } from '../models/area-search.model';

import { BaseService } from 'src/app/shared/base.service';
import { LabelValueModel } from 'src/app/shared/models/label-value.model';
import { AreaDetailsModel } from '../models/area-details.model';
import { KeyValueParentModel } from 'src/app/shared/models/key-value-parent.model';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/area`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有辖区列表
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, string, string>>> {
    const url = `${this.apiUrl}/getall`;
    return this.http.get<ListResponse<KeyValueParentModel<string, string, string>>>(url);
  }

  /**
   * 获取辖区分页集合
   * @param parameter 查询参数
   */
  queryDictionAreaPageList(parameter: AreaParameter)
    : Observable<PagedResponse<AreaSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);

    return this.http.get<PagedResponse<AreaSearchModel>>(this.apiUrl, { params });
  }

  /**
   * 获取辖区树结构
   * @param exceptId 排除部分不参与构造的节点
   */
  getDictionAreaTree(exceptId?: string): Observable<ListResponse<LabelValueModel<string, string>>> {
    const url = `${this.apiUrl}/gettree`;

    const params = this.removeEmptyProps({ exceptId });

    return this.http.get<ListResponse<LabelValueModel<string, string>>>(url, { params });
  }

  /**
   * 获取辖区详情
   * @param id 辖区ID
   */
  getDictionArea(id: string): Observable<SingleResponse<AreaDetailsModel>> {

    const url = `${this.apiUrl}/${id}`;

    return this.http.get<SingleResponse<AreaDetailsModel>>(url);
  }

  /**
   * 新增辖区
   * @param area 辖区实体
   */
  addDictionArea(area: AreaModel): Observable<SingleResponse<AreaModel>> {

    return this.http.post<SingleResponse<AreaModel>>(this.apiUrl, area, this.httpOptions);
  }

  /**
   * 更新辖区
   * @param area 辖区实体
   */
  updateDictionArea(area: AreaModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${area.id}`;

    return this.http.put<NocontentResponse>(url, area, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除辖区
   * @param id 辖区ID
   */
  deleteDictionArea(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
