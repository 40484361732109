import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { LabelValueModel } from '../models/label-value.model';
import { AreaService } from '../../system-manage/services/area.service';
import { tap, map, shareReplay, mergeMap } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { Observable, of, forkJoin } from 'rxjs';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class AreaStorageService {

  private readonly DICKEY: string = 'AREA_KEY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private areaService: AreaService
  ) { }

  /**
   * 辖区缓存初始化
   */
  public setStorage(): Observable<ListResponse<KeyValueParentModel<string, string, string>>> {
    if (!this.http$) {
      this.http$ = this.areaService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<KeyValueParentModel<string, string, string>>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取辖区缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {

    const result = this.localStorageService.get<Array<KeyValueParentModel<string, string, string>>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map((value: ListResponse<KeyValueParentModel<string, string, string>>, index) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    return of(result);
  }

  /**
   * 获取有效辖区缓存
   */
  public getEffectiveStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据辖区编码获取辖区名称
   * @param code 辖区编码
   */
  public getName(code: string): Observable<string> {
    let result = '';
    // 获取辖区项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
        // 判断值是否存在
        if (!value) {
          return result;
        }
        const item = value.find(x => x.value === code);
        result = item ? item.key : '';
        return result;
      })
    );
  }

  /**
   * 获取机构层级编码
   * @param code 编码
   * @param includeCurrent 是否包括当前项
   */
  public getParentCodes(code: string, includeCurrent: boolean = false): Array<string> {
    let result: Array<string> = new Array<string>();
    if (code) {
      const codes = code.split('$');
      codes.forEach(x => {
        if (result.length) {
          result.push(`${result[result.length - 1]}$${x}`);
        } else {
          result.push(`${x}`);
        }
      });
    }

    if (!includeCurrent && result.length) {
      result = result.slice(0, result.length - 2);
    }

    return result;
  }

  /**
   * 获取辖区树
   * @param code 辖区父编码
   * @param exceptCode 排除的辖区编码
   */
  public getTree(code?: string, exceptCode?: string): Observable<Array<any>> {
    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
        const result = this.recurrence(value, code, exceptCode);

        return result;
      })
    );
  }

  /**
   * 获取有效辖区树
   * @param code 辖区编码
   * @param exceptCode 排除的辖区编码
   * @param includeCode 包括的辖区编码
   */
  public getEffectiveTree(code?: string, exceptCode?: string, includeCode?: string)
    : Observable<Array<KeyValueParentModel<string, string, string>>> {
    // 判断是否包含特定项
    if (includeCode) {
      return forkJoin([
        this.getParentCodes(includeCode, true),
        this.getStorage(),
      ]).pipe(
        map(
          (value: Array<any>, index: number) => {
            // 获取所有上级编码
            const parentCodes: Array<string> = value[0] ? value[0] : [];

            // 获取字典项
            const jurisdictionAreas: Array<KeyValueParentModel<string, string, string>> =
              value[1] ? value[1].filter(x => x.dataStatus !== Deleted.Deleted || parentCodes.indexOf(x.value) >= 0) : [];
            const result = this.recurrence(jurisdictionAreas, code, exceptCode);

            return result;
          }
        )
      );
    } else {
      // 获取字典项
      return this.getEffectiveStorage().pipe(
        map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
          const result = this.recurrence(value, code, exceptCode);

          return result;
        })
      );
    }
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

  /**
   * 递归构造树
   * @param sources 数据源
   * @param code 编码
   * @param exceptCode 排除的子树
   */
  private recurrence(sources: Array<KeyValueParentModel<string, string, string>>, code?: string, exceptCode?: string): Array<any> {
    let result = [];

    if (!sources) {
      return result;
    }

    const func = (parentId?: string, exceptCurrentId?: string): Array<LabelValueModel<string, string>> => {

      if (!parentId) {
        parentId = null;
      }
      const items: any = [...sources]
        .filter(x => x.parent === parentId && x.value !== exceptCurrentId)
        .map(x => ({ label: x.key, value: x.value }));

      if (items) {
        items.forEach(x => {
          const children = func(x.value, exceptCurrentId);
          if (children && children.length) {
            x.children = children;
          } else {
            x.isLeaf = true;
          }
        });
      }
      return items;
    };

    const temp = func(code, exceptCode);
    // 逻辑处理是否将当前项追加
    const current = sources.find(x => !!code && x.value === code);
    if (current && code !== exceptCode) {
      result.push({ label: current.key, value: current.value, children: temp });
    } else {
      result = temp;
    }

    return result;
  }

}
