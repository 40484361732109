import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../../shared/base.service';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { OrganizationModel } from '../models/organization.model';
import { OrganizationParameter } from '../models/organization-parameter';
import { OrganizationSearchModel } from '../models/organization-search.model';
import { LabelValueModel } from '../../shared/models/label-value.model';
import { OrganizationDetailsModel } from '../models/organization-details.model';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends BaseService {
  // URL to web api
  private organizationUrl = `${this.apiUrlBase}/api/organization`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取组织机构字典
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, string, string>>> {
    const url = `${this.organizationUrl}/getall`;
    return this.http.get<ListResponse<KeyValueParentModel<string, string, string>>>(url);
  }

  /**
   * 获取机构分页集合
   * @param organizationParameter 查询参数
   */
  queryOrganizationPageList(organizationParameter: OrganizationParameter)
    : Observable<PagedResponse<OrganizationSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(organizationParameter);
    return this.http.get<PagedResponse<OrganizationSearchModel>>(this.organizationUrl, { params });
  }

  /**
   * 获取所有机构树结构
   * @param exceptId 排除部分不参与构造的节点
   */
  getOrganizationTree(exceptId?: string): Observable<ListResponse<LabelValueModel<string, string>>> {
    const url = `${this.organizationUrl}/gettree`;

    const params = this.removeEmptyProps({ exceptId });

    return this.http.get<ListResponse<LabelValueModel<string, string>>>(url, { params });
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  getOrganization(id: string): Observable<SingleResponse<OrganizationDetailsModel>> {

    const url = `${this.organizationUrl}/${id}`;

    return this.http.get<SingleResponse<OrganizationDetailsModel>>(url);
  }

  /**
   * 新增机构
   * @param organization 机构实体
   */
  addOrganization(organization: OrganizationModel): Observable<SingleResponse<OrganizationModel>> {

    return this.http.post<SingleResponse<OrganizationModel>>(this.organizationUrl, organization, this.httpOptions);
  }

  /**
   * 更新机构
   * @param organization 机构实体
   */
  updateOrganization(organization: OrganizationModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.organizationUrl}/${organization.id}`;

    return this.http.put<NocontentResponse>(url, organization, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.organizationUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除机构
   * @param id 机构ID
   */
  deleteOrganization(id: string): Observable<NocontentResponse> {
    const url = `${this.organizationUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
