import { Pipe, PipeTransform } from '@angular/core';
import { PetKindStorageService } from '../storages/pet-kind-storage.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'petKindName'
})
export class PetKindNamePipe implements PipeTransform {

  constructor(private petKindStorageService: PetKindStorageService) {

  }

  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.petKindStorageService.getName(value);
  }
}
