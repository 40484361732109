import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponse } from '../../shared/models/list-response';
import { tap } from 'rxjs/operators';
import { SingleResponse } from '../../shared/models/single-response';
import { LimitedAreaStrategyModel } from '../models/limited-area-strategy.model';
import { NocontentResponse } from '../../shared/models/nocontent-response';
import { LimitedAreaStrategySearchModel } from '../models/limited-area-strategy-search.model';

@Injectable({
  providedIn: 'root'
})
export class LimitedAreaStrategyService extends BaseService {
  // URL to web api
  private limitedAreaStrategyUrl = `${this.apiUrlBase}/api/limitedareastrategy`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有限养区策略列表
   */
  getAll(): Observable<ListResponse<LimitedAreaStrategySearchModel>> {
    const url = `${this.limitedAreaStrategyUrl}/getall`;
    return this.http.get<ListResponse<LimitedAreaStrategySearchModel>>(url);
  }

  /**
   * 批量新增限养区策略
   * @param areaType 限养区类型
   * @param limitedAreaStrategies 限养区策略集合
   */
  update(areaType: number, limitedAreaStrategies: Array<LimitedAreaStrategyModel>): Observable<SingleResponse<LimitedAreaStrategyModel>> {

    const url = `${this.limitedAreaStrategyUrl}/${areaType}`;

    return this.http
      .put<SingleResponse<LimitedAreaStrategyModel>>(url, limitedAreaStrategies, this.httpOptions)
      .pipe(
        tap(
          (response: SingleResponse<LimitedAreaStrategyModel>) => {
          }
        )
      );
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.limitedAreaStrategyUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除限养区策略
   * @param id 限养区策略ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.limitedAreaStrategyUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions).pipe(
      tap(
        (response: NocontentResponse) => {
        }
      )
    );
  }
}
