import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { DictionaryStorageService } from './dictionary-storage.service';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { LabelValueModel } from '../models/label-value.model';
import { PetKindService } from '../../system-manage/services/pet-kind.service';
import { ListResponse } from '../models/list-response';
import { tap, map, shareReplay, mergeMap } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class PetKindStorageService {

  private readonly DICKEY: string = 'PET_KIND_KEY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private dictionaryStorageService: DictionaryStorageService,
    private petKindService: PetKindService
  ) { }

  /**
   * 犬种缓存初始化
   */
  public setStorage(): Observable<ListResponse<KeyValueParentModel<string, string, number>>> {
    if (!this.http$) {
      this.http$ = this.petKindService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<KeyValueParentModel<string, string, number>>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取犬种缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, string, number>>> {

    const result = this.localStorageService.get<Array<KeyValueParentModel<string, string, number>>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map((value: ListResponse<KeyValueParentModel<string, string, number>>, index) => {
          return (!value || value.didError) ? [] : value.model;
        })
      );
    }

    return of(result);
  }

  /**
   * 获取有效犬种缓存
   */
  public getEffectiveStorage(): Observable<Array<KeyValueParentModel<string, string, number>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据犬类Id查询犬种
   * @param parentId 犬类ID
   */
  public getItems(parentId: number): Observable<Array<KeyValueParentModel<string, string, number>>> {
    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {

        if (!value) {
          return [];
        }

        const items: any = value.filter(x => x.parent === parentId);

        return items;
      })
    );
  }

  /**
   * 根据犬类Id查询有效犬种
   * @param parentId 犬类ID
   */
  public getEffectiveItems(parentId: number, include?: string): Observable<Array<KeyValueParentModel<string, string, number>>> {
    let result: Observable<Array<KeyValueParentModel<string, string, number>>> = of([]);

    if (!parentId) {
      return result;
    }

    // 判断是否包含指定项
    if (include) {
      // 获取犬种
      result = this.getStorage().pipe(
        map((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {

          if (!value) {
            return [];
          }

          const items: any = value.filter(x => x.parent === parentId && (x.dataStatus !== Deleted.Deleted || x.value === include));

          return items;
        })
      );
    } else {
      result = this.getEffectiveStorage().pipe(
        map((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {

          if (!value) {
            return [];
          }

          const items: any = value.filter(x => x.parent === parentId);

          return items;
        })
      );
    }

    return result;
  }

  /**
   * 根据犬种ID获取犬种名称
   * @param id 犬种ID
   */
  public getName(id: string): Observable<string> {
    let result = '';

    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {
        // 判断值是否存在
        if (!value) {
          return result;
        }

        const item = value.find(x => x.value === id);
        result = item ? item.key : '';

        return result;
      })
    );
  }

  /**
   * 根据犬种ID获取犬类值
   * @param id 犬种ID
   */
  public getDogType(id: string): Observable<number> {
    let result = 0;

    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {
        // 判断值是否存在
        if (!value) {
          return result;
        }

        const item = value.find(x => x.value === id);
        result = item ? item.parent : 0;

        return result;
      })
    );
  }

  /**
   * 根据犬种ID获取犬种名称
   * @param id 犬种ID
   */
  public getTreeName(id: string): Observable<string> {
    const result = '';

    // 获取字典项
    return this.getStorage().pipe(
      mergeMap((value: Array<KeyValueParentModel<string, string, number>>, index: number) => {

        if (!value) {
          return result;
        }

        const item = value.find(x => x.value === id);

        if (item) {
          return this.dictionaryStorageService.getName(item.parent, 'DogType').pipe(
            map((dogTypeValue: string, dogTypeIndex: number) => {
              return `${dogTypeValue}/${item.key}`;
            })
          );
        }

        return of(result);
      })
    );
  }

  /**
   * 获取犬种树
   * @param exceptNonChild 是否排除没有子节点的元素
   * @param disabledDogKindes 禁用的犬种ID
   */
  public getTree(exceptNonChild?: boolean, disabledDogKindes?: Array<string>): Observable<Array<LabelValueModel<string, string>>> {

    return forkJoin([
      this.dictionaryStorageService.getItems('DogType'),
      this.getStorage()
    ]).pipe(
      map((value, index: number) => {
        const dogTypes = value[0];
        const dogKinds = value[1];

        const result: Array<LabelValueModel<string, string>> = this.dogKindTree(dogTypes, dogKinds, exceptNonChild, disabledDogKindes);

        return result;
      })
    );
  }

  /**
   * 获取有效犬种树
   * @param exceptNonChild 是否排除没有子节点的元素
   * @param disabledDogKindes 禁用的犬种ID
   * @param include 包含特定项
   */
  // tslint:disable-next-line:max-line-length
  public getEffectiveTree(exceptNonChild?: boolean, disabledDogKindes?: Array<string>, include?: string): Observable<Array<LabelValueModel<string, string>>> {
    // 判断是否包含指定项
    if (include) {
      return forkJoin([
        this.getDogType(include),
        this.dictionaryStorageService.getStorage(),
        this.getStorage()
      ]).pipe(
        map((value, index: number) => {
          // 犬类
          const includeDogType = value[0] ? value[0] : [];
          // 获取犬类字典
          const dictionaries = value[1] ? value[1].filter(x => x.parent === 'DogType') : [];
          // 获取有效犬类
          // tslint:disable-next-line:max-line-length
          const dogTypes = dictionaries ? dictionaries.filter(x => x.dataStatus !== Deleted.Deleted || (includeDogType && includeDogType === x.value)) : [];
          // 获取有效犬种
          const dogKinds = value[2] ? value[2].filter(x => x.dataStatus !== Deleted.Deleted || x.value === include) : [];
          // 构造级联树
          const result: Array<LabelValueModel<string, string>> = this.dogKindTree(dogTypes, dogKinds, exceptNonChild, disabledDogKindes);

          return result;
        })
      );
    } else {
      return forkJoin([
        this.dictionaryStorageService.getEffectiveItems('DogType'),
        this.getEffectiveStorage()
      ]).pipe(
        map((value, index: number) => {
          const dogTypes = value[0];
          const dogKinds = value[1];

          const result: Array<LabelValueModel<string, string>> = this.dogKindTree(dogTypes, dogKinds, exceptNonChild, disabledDogKindes);

          return result;
        })
      );
    }
  }

  /**
   * 犬种犬类级联构造
   * @param dogTypes 犬种
   * @param dogKinds 犬类
   * @param exceptNonChild 是否排除没有犬种的犬类
   * @param disabledDogKindes 禁用犬种
   */
  dogKindTree(
    dogTypes: Array<any>,
    dogKinds: Array<KeyValueParentModel<string, string, number>>,
    exceptNonChild?: boolean,
    disabledDogKindes?: Array<string>
  ): Array<LabelValueModel<string, string>> {
    const result: Array<LabelValueModel<string, string>> = [];

    if (dogTypes) {

      dogTypes.forEach(x => {

        // 判断犬种是否存在
        if (dogKinds && dogKinds.length) {
          const children = dogKinds.filter(dogKind => dogKind.parent === x.value).map((item: any) => {
            const temp: LabelValueModel<string, string> = {
              label: item.key,
              value: item.value,
              code: item.code,
              isLeaf: true,
              disabled: disabledDogKindes ? (disabledDogKindes.indexOf(item.value) >= 0) : false
            };

            return temp;
          });

          // 判断是否需要排除没有下级节点的元素
          if (!exceptNonChild || (children && children.length)) {
            result.push(new LabelValueModel<string, string>({
              label: x.key,
              value: x.value.toString(),
              code: x.key,
              children: [...children]
            })
            );
          }
        }

      });
    }

    return result;
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
