import { Pipe, PipeTransform } from '@angular/core';
import { NationalStorageService } from '../storages/national-storage.service';
import { Observable } from 'rxjs';
import { KeyValue } from '@angular/common';
import { KeyValueModel } from '../models/key-value.model';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'national'
})
export class NationalPipe implements PipeTransform {

  constructor(
    private nationalStorageService: NationalStorageService
  ) { }

  transform(value?: any): Observable<KeyValue<string, string>[]> {
    return this.nationalStorageService.getStorage().pipe(
      map((data: Array<KeyValueModel<string, string>>, index: number) => {
        return data.map(x => ({ key: x.key, value: x.value }));
      })
    );
  }
}
