import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ProhibitionStrategyService } from '../../system-manage/services/prohibition-strategy.service';
import { tap, map, shareReplay, mergeMap } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { Observable, of } from 'rxjs';
import { ProhibitionStrategySearchModel } from '../../system-manage/models/prohibition-strategy-search.model';
import { PetKindStorageService } from './pet-kind-storage.service';
import { KeyValueParentModel } from '../models/key-value-parent.model';

@Injectable({
  providedIn: 'root'
})
export class ProhibitionStrategyStorageService {

  private readonly DICKEY: string = 'PROHIBITION_STRATEGY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private prohibitionStrategyService: ProhibitionStrategyService,
    private petKindStorageService: PetKindStorageService
  ) { }

  /**
   * 策略缓存初始化
   */
  public setStorage(): Observable<ListResponse<ProhibitionStrategySearchModel>> {
    if (!this.http$) {
      this.http$ = this.prohibitionStrategyService.getAll().pipe(
        shareReplay()
        // publishReplay(),
        // refCount()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: ListResponse<any>) => {
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取禁养策略项缓存
   */
  public getStorage(): Observable<Array<ProhibitionStrategySearchModel>> {

    const result = this.localStorageService.get<Array<ProhibitionStrategySearchModel>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        mergeMap((value: ListResponse<ProhibitionStrategySearchModel>, index) => {
          const prohibitionStrategies = (!value || value.didError) ? [] : value.model;
          return this.petKindStorageService.getStorage().pipe(
            map((dogKinds: Array<KeyValueParentModel<string, string, number>>, dogKindsIndex: number) => {
              const items = prohibitionStrategies.map(x => {
                const dogKind = dogKinds.find(item => item.value === x.petKindId);

                return new ProhibitionStrategySearchModel({
                  //  主键
                  id: x.id,
                  // 限养区类型
                  areaType: x.areaType,
                  // 犬种ID
                  petKindId: x.petKindId,
                  // 犬类
                  petType: dogKind ? dogKind.parent : 0,
                  // 创建人
                  createdBy: x.createdBy,
                  // 创建人名称
                  createdByName: x.createdByName,
                  // 创建时间
                  created: x.created,
                  // 最后修改人
                  lastModifiedBy: x.lastModifiedBy,
                  // 最后修改人姓名
                  lastModifiedByName: x.lastModifiedByName,
                  // 最后修改时间
                  lastModified: x.lastModified,
                  // 行版本
                  rowVersion: x.rowVersion
                });
              });

              return items;
            })
          );
        })
      );
    }

    return this.petKindStorageService.getStorage().pipe(
      map((dogKinds: Array<KeyValueParentModel<string, string, number>>, dogKindsIndex: number) => {
        const items = result.map(x => {
          const dogKind = dogKinds.find(item => item.value === x.petKindId);

          return new ProhibitionStrategySearchModel({
            //  主键
            id: x.id,
            // 限养区类型
            areaType: x.areaType,
            // 犬种ID
            petKindId: x.petKindId,
            // 犬类
            petType: dogKind ? dogKind.parent : 0,
            // 创建人
            createdBy: x.createdBy,
            // 创建人名称
            createdByName: x.createdByName,
            // 创建时间
            created: x.created,
            // 最后修改人
            lastModifiedBy: x.lastModifiedBy,
            // 最后修改人姓名
            lastModifiedByName: x.lastModifiedByName,
            // 最后修改时间
            lastModified: x.lastModified,
            // 行版本
            rowVersion: x.rowVersion
          });
        });

        return items;
      })
    );
  }

  /**
   * 根据限养区类型获取禁养策略所禁养的犬
   * @param areaType 限养区类型ID
   */
  public getDogKindes(areaType: number): Observable<Array<{ [key: string]: string | number }>> {
    let result = [];

    if (!areaType) {
      return of(result);
    }

    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<ProhibitionStrategySearchModel>, index: number) => {
        if (!value) {
          return result;
        }

        result = value.filter(x => +x.areaType === +areaType).map(x => ({ petKindId: x.petKindId, petType: x.petType }));

        return result;
      })
    );
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
