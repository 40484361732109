import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { NationalModel } from '../models/national.model';
import { NationalParameter } from '../models/national-parameter';
import { NationalSearchModel } from '../models/national-search.model';

import { BaseService } from '../../shared/base.service';
import { KeyValueModel } from '../../shared/models/key-value.model';

@Injectable({
  providedIn: 'root'
})
export class NationalService extends BaseService {
  // URL to web api
  private nationalUrl = `${this.apiUrlBase}/api/national`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取民族分页集合
   * @param nationalParameter 查询参数
   */
  queryNationalPageList(nationalParameter: NationalParameter)
    : Observable<PagedResponse<NationalSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(nationalParameter);
    return this.http.get<PagedResponse<NationalSearchModel>>(this.nationalUrl, { params });
  }

  /**
   * 获取所有民族字典
   */
  getNationalKeyValue(): Observable<ListResponse<KeyValueModel<string, string>>> {
    const url = `${this.nationalUrl}/getkeyvalue`;

    return this.http.get<ListResponse<KeyValueModel<string, string>>>(url);
  }

  /**
   * 获取民族详情
   * @param id 民族ID
   */
  getNational(id: string): Observable<SingleResponse<NationalModel>> {

    const url = `${this.nationalUrl}/${id}`;

    return this.http.get<SingleResponse<NationalModel>>(url);
  }

  /**
   * 新增民族
   * @param national 民族实体
   */
  addNational(national: NationalModel): Observable<SingleResponse<NationalModel>> {
    return this.http.post<SingleResponse<NationalModel>>(this.nationalUrl, national, this.httpOptions);
  }

  /**
   * 更新民族
   * @param national 民族实体
   */
  updateNational(national: NationalModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.nationalUrl}/${national.id}`;

    return this.http.put<NocontentResponse>(url, national, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.nationalUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除民族
   * @param id 民族ID
   */
  deleteNational(id: string): Observable<NocontentResponse> {
    const url = `${this.nationalUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
