import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from '../../../frame/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationErrorHandler } from '../../../shared/validation-error-handler';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { mergeMap } from 'rxjs/operators';
import { SingleResponse } from '../../../shared/models/single-response';
import { of } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public validateForm: FormGroup;
  public passwordVisible = false;
  public isSpinning = false;
  private returnUrl: string;

  /**
   * 构造器
   * @param fb 表单
   * @param router 路由
   * @param router 动态路由
   * @param message 消息模态框
   * @param authService 授权服务
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private authService: AuthService,
    private userResourceStorageService: UserResourceStorageService,
  ) { }

  /**
   * 初始化
   */
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required, Validators.maxLength(20)]],
      password: [null, [Validators.required]],
      remember: [false]
    });

    // reset login status
    this.authService.clearResource();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  /**
   * 登录提交
   * @param $event 句柄
   * @param model 登录模型
   */
  submit($event: any, model: any): void {
    // 循环验证表单
    for (const index in this.validateForm.controls) {
      if (index) {
        this.validateForm.controls[index].markAsDirty();
        this.validateForm.controls[index].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {

      // 登录
      this.authService.login(model).pipe(
        mergeMap(
          (response: SingleResponse<any>, index: number) => {
            const result = of(null);

            if (response && !response.didError) {
              // 判断用户是否登录成功
              if (!response.model || !response.model.access_token) {
                this.message.error('用户登录失败，用户名或密码错误！');
                return result;
              }

              try {
                // 是否加载等待框
                this.isSpinning = true;

                // 获取用户菜单树
                return this.userResourceStorageService.getMenuTree();
              } catch (ex) {
                // 是否加载等待框
                this.isSpinning = false;
                return result;
              }
            } else {
              // 是否加载等待框
              this.isSpinning = false;
              this.message.error('用户登录失败，请检查用户名或密码是否输入有误！');
              return result;
            }
          }
        )
      ).subscribe((menus: any) => {
        if (menus) {
          let returnUrl = this.returnUrl;

          if (this.returnUrl === '/' && menus && menus[0] && menus[0].children && menus[0].children[0]) {
            returnUrl = menus[0].children[0].router;
          }
          // 登录成功，页面跳转
          this.router.navigate([returnUrl]);
          console.log(returnUrl);
        }

        // 是否加载等待框
        this.isSpinning = false;
      },
        validationResult => {
          // 是否加载等待框
          this.isSpinning = false;
          // 异常信息
          ValidationErrorHandler.handleFormValidationErrors(this.validateForm, validationResult);
          // 更新表单验证
          this.validateForm.updateValueAndValidity();
        }
      );
    }
  }
}
