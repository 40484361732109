import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { DictionaryModel } from '../models/dictionary.model';
import { DictionaryParameter } from '../models/dictionary-parameter';
import { DictionarySearchModel } from '../models/dictionary-search.model';

import { BaseService } from '../../shared/base.service';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';
import { KeyValueModel } from '../../shared/models/key-value.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends BaseService {
  // URL to web api
  private dictionaryUrl = `${this.apiUrlBase}/api/dictionary`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有字典项
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, number, string>>> {
    const url = `${this.dictionaryUrl}/getall`;
    // 参数处理
    return this.http.get<ListResponse<KeyValueParentModel<string, number, string>>>(url);
  }

  /**
   * 获取机构分页集合
   * @param dictionaryParameter 查询参数
   */
  queryDictionaryPageList(dictionaryParameter: DictionaryParameter)
    : Observable<PagedResponse<DictionarySearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(dictionaryParameter);
    return this.http.get<PagedResponse<DictionarySearchModel>>(this.dictionaryUrl, { params });
  }

  /**
   * 根据字典分类获取分类字典项
   */
  getDictionaryKeyValue(code: string): Observable<ListResponse<KeyValueModel<string, string>>> {
    const url = `${this.dictionaryUrl}/getkeyvalue/${code}`;
    return this.http.get<ListResponse<KeyValueModel<string, string>>>(url);
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  getDictionary(id: string): Observable<SingleResponse<DictionaryModel>> {

    const url = `${this.dictionaryUrl}/${id}`;

    return this.http.get<SingleResponse<DictionaryModel>>(url);
  }

  /**
   * 新增机构
   * @param dictionary 机构实体
   */
  addDictionary(dictionary: DictionaryModel): Observable<SingleResponse<DictionaryModel>> {

    return this.http.post<SingleResponse<DictionaryModel>>(this.dictionaryUrl, dictionary, this.httpOptions);
  }

  /**
   * 更新机构
   * @param dictionary 机构实体
   */
  updateDictionary(dictionary: DictionaryModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.dictionaryUrl}/${dictionary.id}`;

    return this.http.put<NocontentResponse>(url, dictionary, this.httpOptions);
  }

  /**
   * 撤销删除机构
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.dictionaryUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除机构
   * @param id 机构ID
   */
  deleteDictionary(id: string): Observable<NocontentResponse> {
    const url = `${this.dictionaryUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
