import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'internetSrc'
})
export class InternetSrcPipe implements PipeTransform {
    private baseUrl = environment.SERVER.URL;

    transform(value: string): any {
        let result = '';
        if (value) {
            result = `${this.baseUrl}/${value}`;
        }

        return result;
    }
}
