import { FormGroup } from '@angular/forms';

export class ValidationErrorHandler {
    static handleFormValidationErrors(form: FormGroup, validationResult: any): void {
        // tslint:disable-next-line:forin
        for (const property in validationResult) {
            if (validationResult.hasOwnProperty(property)) {
                // 首字母转小写
                const propertyLower = property.replace(property[0], property[0].toLowerCase());
                if (form.controls[propertyLower]) {
                    // single field
                    const validationErrorsForFormField = {};
                    for (const validationError of validationResult[property]) {
                        validationErrorsForFormField[validationError.validatorKey] = true;
                    }
                    form.controls[propertyLower].setErrors(validationErrorsForFormField);
                } else {
                    // cross field
                    const validationErrorsForForm = {};
                    for (const validationError of validationResult[property]) {
                        validationErrorsForForm[validationError.validatorKey] = true;
                    }
                    form.setErrors(validationErrorsForForm);
                }
            }
        }
    }
}
