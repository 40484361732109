import { Component, OnInit, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { BreedSearchModel } from 'src/app/canine-query/models/breed-search.model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { OwnerType } from '../../../../shared/models/enum';
import { ImgSrcPipe } from 'src/app/shared/pipes/img-src.pipe';

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.less'],
  providers: [
    ImgSrcPipe
  ]
})
export class OwnerDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  @Input() model: BreedSearchModel;

  // 个人养犬模型
  private previewImgModal: NzModalRef<any>;

  public previewUrl: string;
  public ownerType: any = OwnerType;

  public showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: false,
    hidePreviewIconInNonImage: true
  };

  public certificateFrontImgs = [];
  public certificateBackImgs = [];
  public certificateImgs = [];
  public disabledCertificateImgs = [];

  constructor(
    private modalService: NzModalService,
    private imgSrcPipe: ImgSrcPipe,
  ) {
  }

  ngOnInit(): void {
    this.initPhoto(this.model);
  }

  /**
   * 变更钩子
   * @param changes 变更
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      this.initPhoto(this.model);
    }
  }

  ngOnDestroy(): void {
    if (this.previewImgModal) {
      this.previewImgModal.destroy();
    }
  }

  /**
   * 初始化图片
   * @param model 模型
   */
  private initPhoto(model: BreedSearchModel): void {
    if (model) {
      this.certificateFrontImgs = [{
        url: this.imgSrcPipe.transform(model.certificateFrontImg)
      }];

      this.certificateBackImgs = [{
        url: this.imgSrcPipe.transform(model.certificateBackImg)
      }];

      model.certificateImg = model.certificateImg + '?v=' + Math.round(Math.random() * 1000);

      this.certificateImgs = [{
        url: this.imgSrcPipe.transform(model.certificateImg)
      }];

      this.disabledCertificateImgs = [{
        url: this.imgSrcPipe.transform(model.disabledCertificateImg)
      }];
    }
  }

  // handlePreview = (file: UploadFile) => {
  //   this.previewUrl = file.url;
  //   this.createPreviewImgModal(file.name);
  // }

  /**
   * 查看图片
   * @param url 图片地址
   */
  showImage(url: string): void {
    this.previewUrl = this.imgSrcPipe.transform(url) + '?v=' + Math.round(Math.random() * 1000);
    this.createPreviewImgModal();
  }

  createPreviewImgModal(title?: string): void {
    this.previewImgModal = this.modalService.create({
      nzTitle: title ? title : '图片预览',
      nzContent: this.modalContent,
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzOnOk: () => {
        // Ok按钮
      }
    });
  }

  reset(): void {
    // 模型重置
    this.model = null;
    this.previewUrl = null;
    this.certificateFrontImgs = [];
    this.certificateBackImgs = [];
    this.certificateImgs = [];
    this.disabledCertificateImgs = [];
  }
}
