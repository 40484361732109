<form nz-form [formGroup]="validateForm">
    <div nz-row>
        <div nz-col nzMd="24" nzXl="12">
            <nz-form-item nzJustify="start" nzAlign="top">
                <nz-form-label class="form-label-100" nzRequired>说明信息</nz-form-label>
                <nz-form-control class="form-control-1" [nzErrorTip]="errorRemark">
                    <input nz-input formControlName="remark" [nzAutocomplete]="auto" [placeholder]="placeHolder" trim />
                    <ng-template #errorRemark let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            请输入说明信息!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            最多可输入200个字符!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>

<nz-autocomplete #auto nzBackfill="true">
    <!-- <nz-auto-option [nzValue]="'一般限养区变更为重点限养区'">一般限养区变更为重点限养区</nz-auto-option>
    <nz-auto-option [nzValue]="'重点限养区变更为一般限养区'">重点限养区变更为一般限养区</nz-auto-option>
    <nz-auto-option [nzValue]="'基本信息变更'">基本信息变更</nz-auto-option>
    <nz-auto-option [nzValue]="'变更犬主'">变更犬主</nz-auto-option>
    <nz-auto-option [nzValue]="'常规年检'">常规年检</nz-auto-option> -->
    <nz-auto-option *ngFor="let item of options" [nzValue]="item.value">{{item.key}}</nz-auto-option>
</nz-autocomplete>