import { Component, OnInit, TemplateRef, ViewChild, OnChanges, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { Router, ActivatedRoute, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Subscription } from 'rxjs';
import { IndexStorageService } from '../../../shared/storages/index-storage.service';
import { Message } from '@stomp/stompjs';
import { ScreenHelper } from '../../../shared/helpers/screen-helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('trigger', { static: true }) customTrigger: TemplateRef<void>;

  triggerTemplate: TemplateRef<void> | null = null;

  isCollapsed = false;
  isFullScreen = false;

  private topicSubscription: Subscription;
  public receivedMessages: Array<any> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private indexStorageService: IndexStorageService,
    private rxStompService: RxStompService,
    private screenHelper: ScreenHelper
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          // console.log(this.router, event, 'NavigationStart=============');
        } else if (event instanceof NavigationEnd) {
          // console.log(this.router.routerState.snapshot.root, event, 'NavigationEnd=============');
        } else if (event instanceof NavigationCancel) {
          // console.log(this.router, event, 'NavigationCancel=============');
        } else if (event instanceof NavigationError) {
          // console.log(this.router, event, 'NavigationError=============');
        } else if (event instanceof RoutesRecognized) {
          // console.log(this.router, event, 'RoutesRecognized=============');
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): boolean {
    // 清空缓存
    this.indexStorageService.clearStorage();

    return false;
  }

  @HostListener('window:onunload', ['$event'])
  unloadHandler(event: Event): boolean {
    // 清空缓存
    this.indexStorageService.clearStorage();

    return false;
  }

  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  /**
   * 推送消息
   * @param $event 数据对象
   */
  onPushMessage($event: any): void {
  }

  /**
   * rabbitmq消息推送
   * @param $event 句柄
   */
  onRabbitMQpushMessage($event: any): void {
    this.onRabbitMQSendMessage();
  }

  /**
   * 发送消息
   */
  onRabbitMQSendMessage(): void {
    const message = `Message generated at ${new Date()}`;

    const data: any = {
      id: '222222222222222',
      userId: '1222222',
      organizationId: '22222222',
      caption: '测试报送信息推送',
      createDate: new Date()
    };

    const dataStr = JSON.stringify(data);

    if (!this.rxStompService.connected()) {
      this.rxStompService.activate();
    }

    // messages published to the default exchange are given the destination /queue/queuename
    // messages published to amq.topic are given the destination /topic/routing_key;
    // all other messages are given the destination /exchange/exchange_name[/routing_key]
    this.rxStompService.publish({ destination: '/exchange/topic.ex_pets.submission/demo.123', body: dataStr });
  }

  onReceivedMessage(): void {
    this.topicSubscription = this.rxStompService.watch('/topic/demo').subscribe((message: Message) => {

      this.receivedMessages.push(message.body);
    });
  }

  onSendMessage(): void {

    // if (!this.rxStompService.connected()) {
    //   this.rxStompService.activate();
    // }

    // const message = `Message generated at ${new Date}`;
    // this.rxStompService.publish({ destination: '/topic/demo', body: message });
  }

  /**
   * 全屏
   */
  fullScreenClick(): void {
    this.isFullScreen = true;
    // 全屏
    this.screenHelper.fullScreen();
  }

  /**
   * 退出全屏
   */
  exitFullScreenClick(): void {
    this.isFullScreen = false;
    // 退出全屏
    this.screenHelper.exitScreen();
  }

}
