/**
 * 修改用户密码模型
 */
export class ChangePasswordModel {
    /**
     * 用户密码
     */
    password?: string;
    /**
     * 新密码
     */
    newPassword?: string;
    /**
     * 确认密码
     */
    confirmNewPassword?: string;
    /**
     * 行版本
     */
    rowVersion?: Date;

    constructor(init?: Partial<ChangePasswordModel>) {
        Object.assign(this, init);
    }
}
