<nz-drawer [nzClosable]="false" [nzVisible]="visible" nzTooltipTitle="工具箱" (nzOnClose)="close()">
    <div class="toolbox">
        <button nz-button nzType="primary" style="height: 32px; width: 32px; border-radius: 8px 0 0 8px;"
            (click)="open($event)">
            <i nz-icon [nzType]="visible ? 'close' : 'tool'" nzTheme="outline"></i>
        </button>
    </div>

    <form nz-form [nzLayout]="'inline'" [formGroup]="queryForm" (ngSubmit)="querySubmit($event, queryForm.value)">
        <nz-form-item>
            <nz-form-control>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" nz-input formControlName="cardNo" placeholder="登记号、电子标识码" nz-tooltip
                        nzTooltipTitle="登记号" />
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch [disabled]="!queryForm.valid">
                        <i nz-icon nzType="search"></i>
                    </button>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
    </form>

    <nz-list [nzDataSource]="dataSet" [nzRenderItem]="item" [nzItemLayout]="'horizontal'" [nzPagination]="pagination">
        <ng-template #item let-item>
            <nz-list-item [nzActions]="[editAction]">
                <ng-template #editAction>
                    <a (click)="printClick(item.registrationNo)"><i nz-icon nzType="printer" nzTheme="outline"></i></a>
                </ng-template>
                <nz-list-item-meta style="min-width: 138px;" [nzTitle]="nzTooltipTitle"
                    [nzDescription]="item?.created | date: 'yyyy-MM-dd HH:mm:ss'">
                    <ng-template #nzTooltipTitle>
                        <div>{{ item.registrationNo }}</div>
                    </ng-template>
                </nz-list-item-meta>
            </nz-list-item>
        </ng-template>
        <ng-template #pagination>
            <nz-pagination [nzPageIndex]="parameter.pageIndex" [nzPageSize]="parameter.pageSize" [nzTotal]="total"
                nzSimple (nzPageIndexChange)="pageIndexChange($event)">
            </nz-pagination>
        </ng-template>
    </nz-list>
</nz-drawer>