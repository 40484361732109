import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { NzMessageService } from 'ng-zorro-antd/message';

import { YesNo } from 'src/app/shared/models/enum';

import { ChargeModel } from '../../models/charge.model';
import { ChargeStrategyStorageService } from '../../../shared/storages/charge-strategy-storage.service';
import { ChargeStrategyModel } from '../../../system-manage/models/charge-strategy.model';
import { InvoiceService } from 'src/app/system-manage/services/invoice.service';
import { UserResourceStorageService } from 'src/app/shared/storages/user-resource-storage.service';
import { ListResponse } from 'src/app/shared/models/list-response';
import { InvoiceByAmountViewModel } from 'src/app/system-manage/models/invoice-by-amount-view.model';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.less']
})
export class ChargeComponent implements OnInit, OnChanges {
  /**
   * 费用策略
   */
  @Input() model: ChargeModel;
  /**
   * 费用策略
   */
  @Input() strategy: ChargeStrategyModel;
  /**
   * 是否显示发票号元素
   */
  @Input() isShowInvoiceNo = true;

  private invoiceAmount = 0;
  private invoiceNo = null;

  public invoiceNoReadonly = false;

  public validateForm: FormGroup;

  // 是否必填
  public required = false;

  formatterRMB = (value: number) => `￥ ${value ? value : 0}`;
  parserRMB = (value: string) => value.replace('￥ ', '');

  /**
   * 验证
   */
  get valid(): boolean {
    return this.validateForm.valid;
  }

  constructor(
    private fb: FormBuilder,
    private messageService: NzMessageService,
    private userResourceStorageService: UserResourceStorageService,
    private chargeStrategyStorageService: ChargeStrategyStorageService,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit(): void {
    if (!this.model) {
      this.model = new ChargeModel();
    }

    // 表单初始化
    this.validateForm = this.fb.group({
      // 收费标准
      amountReceived: [this.model.amount],
      // 发票号
      invoiceNo: [this.model.invoiceNo]
    });

    // 收费策略
    if (this.strategy) {
      this.changeChargingStrategy(this.strategy.chargeItem, this.strategy.isDisabled, this.strategy.areaType, this.strategy.isNeutered);
    }

    // 判断用户是否有权限自动出发票
    this.userResourceStorageService.getUser().subscribe(userResponse => {
      if (userResponse && userResponse.autoInvoice === 1) {
        // 只读
        this.invoiceNoReadonly = true;
      } else {
        // 只读
        this.invoiceNoReadonly = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.strategy && changes.strategy.currentValue) {
      // 收费策略
      this.changeChargingStrategy(this.strategy.chargeItem, this.strategy.isDisabled, this.strategy.areaType, this.strategy.isNeutered);
    } else {
      if (this.validateForm) {
        this.validateForm.patchValue({
          amountReceived: 0,
          // 发票号
          invoiceNo: null
        });
      }
    }

    // 模型是否变更
    if (changes.model && changes.model.currentValue) {
      if (this.validateForm) {
        this.validateForm.patchValue({
          amountReceived: this.model.amount
        });
      }
    }
  }

  /**
   * 添加表单验证
   */
  setValidators(): void {
    // 发票号码
    this.validateForm.controls.invoiceNo.setValidators(Validators.required);
    // 调用新验证
    this.validateForm.controls.invoiceNo.updateValueAndValidity();
  }

  /**
   * 移除表单验证
   */
  removeValidators(): void {
    // 发票号码
    this.validateForm.controls.invoiceNo.setValidators(null);
    // 调用新验证
    this.validateForm.controls.invoiceNo.updateValueAndValidity();
  }

  /**
   * 收费金额变更
   * @param $event 收费金额
   */
  amountModelChange($event: number): void {
    if ($event) {
      this.required = true;
      this.setValidators();
    } else {
      this.required = false;
      this.removeValidators();
    }
  }

  /**
   * 排除发票号验证
   */
  excludeInvoiceNoValidators(): void {
    this.required = false;
    // 发票号码
    this.validateForm.controls.invoiceNo.setValidators(null);
    this.validateForm.controls.invoiceNo.clearValidators();
    this.validateForm.controls.invoiceNo.clearAsyncValidators();
    this.validateForm.controls.invoiceNo.markAsPristine();
    this.validateForm.controls.invoiceNo.markAsUntouched();
    // 调用新验证
    this.validateForm.controls.invoiceNo.updateValueAndValidity();
  }

  /**
   * 收费策略
   * @param chargeItem 收费项
   * @param unabled 残疾类型
   * @param areaType 限养区类型
   * @param isNeutered 是否绝育
   */
  chargingStrategy(chargeItem: number, unabled: number, areaType: number, isNeutered: number): Observable<ChargeStrategyModel> {
    // 收费策略
    const strategy = this.chargeStrategyStorageService.getChargeStrategy(chargeItem, unabled, areaType, isNeutered);

    return strategy;
  }

  /**
   * 更新收费策略
   * @param chargeItem 收费项
   * @param unabled 是否残疾
   * @param areaType 限养区类型
   * @param isNeutered 是否绝育
   */
  changeChargingStrategy(chargeItem: number, unabled: number, areaType: number, isNeutered: number): void {
    // console.log(`收费项：${chargeItem}；是否残疾：${unabled}，限养区类型：${areaType}，是否绝育：${isNeutered}`, 'changeChargingStrategy数据源');
    // 收费策略
    this.chargingStrategy(chargeItem, unabled, areaType, isNeutered).subscribe(
      (response: ChargeStrategyModel) => {
        if (response) {
          this.model = new ChargeModel({
            // 收费类型
            chargeType: response.chargeType,
            // 收费项类型
            chargeItem: response.chargeItem,
            // 实收费用标准
            amountReceived: response.amount
          });

          // 费用标准
          this.chargeStrategyStorageService.getChargeAmount(chargeItem, YesNo.No, areaType, YesNo.No).subscribe(
            (chargeResponse: number) => {
              if (chargeResponse) {
                this.model.amount = chargeResponse;
              }
            }
          );

          // 金额
          if (this.validateForm) {
            this.validateForm.patchValue({
              amountReceived: this.model.amountReceived
            });
          }
        }
      }
    );
  }

  /**
   * 生成发票
   * @param event 句柄
   */
  public invoinceClick(event): void {
    const amount = this.validateForm.value.amountReceived;
    // 是否需要生成发票
    if (amount > 0) {
      // 获取金额
      this.invoiceService.getByAmount(amount).subscribe((invoiceResponse: ListResponse<InvoiceByAmountViewModel>) => {
        if (!invoiceResponse.didError) {
          const invoices: Array<InvoiceByAmountViewModel> = invoiceResponse.model;
          const invoiceNos = invoices.map(x => `${x.invoiceNum}[${x.denomination}元]`).join(',');
          // 发票号
          this.validateForm.patchValue({ invoiceNo: invoiceNos });
          this.invoiceNo = invoices.map(x => `${x.invoiceNum}`).join(',');
          // 计算金额
          const denominations = invoices.map(x => x.denomination);
          this.invoiceAmount = denominations.reduce((n, m) => n + m);
        }
      },
        err => {
          // this.messageService.warning('');
        });
    } else {
      this.invoiceAmount = 0;
      this.invoiceNo = null;
      this.validateForm.patchValue({ invoiceNo: null });
    }
  }

  /**
   * 表单提交
   */
  public preSubmit(): ChargeModel {
    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    // 计算票面金额是否有效
    if (this.invoiceNoReadonly && this.model.amountReceived !== this.invoiceAmount) {
      this.messageService.warning('业务金额与票面金额不符');
      return null;
    }

    // 验证不成功返回空
    if (!this.validateForm.valid) {
      return null;
    }

    const model = this.validateForm.getRawValue();

    if (this.invoiceNoReadonly && model.amountReceived === 0) {
      this.invoiceNo = null;
    }

    const charge = new ChargeModel({
      // 收费项 1：登记；2：年检
      chargeItem: this.model.chargeItem,
      // 收费类型：1：免费；2：半价；3：全额
      chargeType: this.model.chargeType,
      // 费用标准
      amount: this.model.amount,
      // 实收金额
      amountReceived: model.amountReceived,
      // 发票号
      invoiceNo: this.invoiceNoReadonly ? this.invoiceNo : model.invoiceNo
    });

    return charge;
  }

  /**
   * 表单仅提交费用标准
   */
  public preSubmitOnlyAmount(): ChargeModel {
    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      if (key === 'invoiceNo') { continue; }
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    // 排除验证
    this.excludeInvoiceNoValidators();

    // 验证不成功返回空
    if (!this.validateForm.valid) {
      return null;
    }

    const model = this.validateForm.getRawValue();

    const charge = new ChargeModel({
      // 收费项 1：登记；2：年检
      chargeItem: this.model.chargeItem,
      // 收费类型：1：免费；2：半价；3：全额
      chargeType: this.model.chargeType,
      // 费用标准
      amount: this.model.amount,
      // 实收金额
      amountReceived: model.amountReceived,
    });

    return charge;
  }

  /**
   * 重置表单
   */
  public reset(): void {
    this.validateForm.reset();

    this.removeValidators();
    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }
}
