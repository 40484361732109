import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleResponse } from 'src/app/shared/models/single-response';
import { PagedResponse } from 'src/app/shared/models/paged-response';
import { BreedPagedParameter } from 'src/app/canine-query/models/breed-paged.parameter';
import { BreedSearchModel } from 'src/app/canine-query/models/breed-search.model';
import { BreedInputModel } from '../models/breed-input.model';
import { AnnualInspectionInputModel } from '../models/annual-inspection-input.model';
import { CancellationInputModel } from '../models/cancellation-input.model';
import { WriteCardModel } from '../models/write-card.model';
import { BreedDetailsModel } from 'src/app/canine-query/models/breed-details.model';

@Injectable({
  providedIn: 'root'
})
export class BreedService extends BaseService {

  private api = `${this.apiUrlBase}/api/breed`;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 养犬分页查询
   * @param parameter 分页查询参数
   */
  paged(parameter: BreedPagedParameter): Observable<PagedResponse<BreedSearchModel>> {
    const url = `${this.api}`;
    const params = this.removeEmptyProps(parameter);

    return this.http.get<PagedResponse<BreedSearchModel>>(url, { params });
  }

  /**
   * 获取养犬登记详情
   * @param query 登记号、电子标识码
   */
  details(query: any): Observable<SingleResponse<BreedDetailsModel>> {
    const url = `${this.api}/${query}`;

    return this.http.get<SingleResponse<BreedDetailsModel>>(url);
  }

  /**
   * 根据预约号获取预约详情
   * @param applyNo 预约号
   */
  detailsForBreed(applyNo: string): Observable<SingleResponse<BreedDetailsModel>> {
    const apiUrl = `${this.api}/detailsForBreed/${applyNo}`;

    return this.http.get<SingleResponse<BreedDetailsModel>>(apiUrl);
  }

  /**
   * 登记
   * @param inputModel 输入模型
   */
  add(inputModel: BreedInputModel): Observable<SingleResponse<string>> {
    const url = `${this.api}`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.post<SingleResponse<string>>(url, body, this.httpOptions);
  }

  /**
   * 变更
   * @param inputModel 输入模型
   */
  update(inputModel: BreedInputModel): Observable<SingleResponse<number>> {
    const url = `${this.api}`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.put<SingleResponse<number>>(url, body, this.httpOptions);
  }

  /**
   * 年检
   * @param inputModel 输入模型
   */
  annualInspection(inputModel: AnnualInspectionInputModel): Observable<SingleResponse<number>> {
    const url = `${this.api}/annualInspection`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<number>>(url, body, this.httpOptions);
  }

  /**
   * 注销
   * @param inputModel 输入模型
   */
  cancellation(inputModel: CancellationInputModel): Observable<SingleResponse<number>> {
    const url = `${this.api}/cancellation`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<number>>(url, body, this.httpOptions);
  }

  /**
   * 吊销
   * @param inputModel 输入模型
   */
  revoked(inputModel: CancellationInputModel): Observable<SingleResponse<number>> {
    const url = `${this.api}/revoked`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<number>>(url, body, this.httpOptions);
  }

  /**
   * 补发犬证
   * @param model 补发犬证模型
   */
  reissueCertification(inputModel: any): Observable<SingleResponse<string>> {
    const url = `${this.api}/reissue`;

    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<string>>(url, body, this.httpOptions);
  }

  /**
   * 撤销操作
   * @param petId 犬ID
   */
  undo(petId: string): Observable<SingleResponse<any>> {
    const url = `${this.api}/undo/${petId}`;
    return this.http.patch<SingleResponse<any>>(url, null, this.httpOptions);
  }

  /**
   * 更新制卡标志
   * @param model 制卡标志模型
   */
  updateCardFlag(inputModel: WriteCardModel): Observable<SingleResponse<number>> {
    const url = `${this.api}/updateCardFlag`;
    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<number>>(url, body, this.httpOptions);
  }

  /**
   * 更新电子标识码
   * @param inputModel 输入模型
   */
  updateTagNo(inputModel: WriteCardModel): Observable<SingleResponse<string>> {
    const url = `${this.api}/updateTagNo`;
    const body = this.removeEmptyProps(inputModel);

    return this.http.patch<SingleResponse<string>>(url, body, this.httpOptions);
  }

}
