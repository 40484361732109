import { Pipe, PipeTransform } from '@angular/core';
import { NationalStorageService } from '../storages/national-storage.service';
import { of, Observable } from 'rxjs';

@Pipe({
  name: 'nationalName'
})
export class NationalNamePipe implements PipeTransform {

  constructor(
    private nationalStorageService: NationalStorageService
  ) {
  }

  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.nationalStorageService.getName(value);
  }
}
