import { Component, forwardRef, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PhotoHelper } from 'src/app/shared/helpers/photo-helper';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

export const EXE_IMAGE_UPLOAD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CameraImageComponent),
  multi: true
};

@Component({
  selector: 'app-camera-image',
  templateUrl: './camera-image.component.html',
  styleUrls: ['./camera-image.component.less'],
  providers: [EXE_IMAGE_UPLOAD_VALUE_ACCESSOR]
})
export class CameraImageComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  @Input() iconName: string;
  @Input() isMini = false;
  /**
   * 是否识别照片
   */
  @Input() isDetection = false;
  /**
   * 建档ID
   */
  @Input() petArchivesId?: string;
  /**
   * 变更事件
   */
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChanged = new EventEmitter<any>();

  private previewImgModal: NzModalRef<any>;

  public backgroundImage = null;
  public model: string;
  // 文件api
  public fileApi = `${environment.SERVER.URL}/api/file?isDetection=${this.isDetection}&petArchivesId=${this.petArchivesId ? this.petArchivesId : ''}`;
  public imgList: Array<NzUploadFile> = [];
  // 拍照
  public isCamera = false;
  public showUploadList: boolean | { showPreviewIcon?: boolean, showRemoveIcon?: boolean, hidePreviewIconInNonImage?: boolean } = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };

  previewUrl: string;

  public get showButton(): boolean {
    if (!this.imgList) {
      return true;
    }

    return this.imgList.length < 1;
  }

  public set showButton(value) {
    if (!this.imgList) {
      this.showButton = value;
    }
  }

  constructor(
    private modalService: NzModalService,
    private message: NzMessageService,
    private photoHelper: PhotoHelper
  ) { }

  /**
   * 预览
   */
  handlePreview = (file: NzUploadFile) => {
    this.previewUrl = file.url || file.thumbUrl;
    this.createPreviewImgModal(file.name);
  }

  /**
   * 移除文件
   */
  handleRemove = (file: NzUploadFile) => {
    // 模型处理
    this.changeValue(null);
    return true;
  }

  createPreviewImgModal(title?: string): void {
    this.previewImgModal = this.modalService.create({
      nzTitle: title ? title : '图片预览',
      nzContent: this.modalContent,
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzOnOk: () => {
        // Ok按钮
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconName && changes.iconName.currentValue) {
      this.backgroundImage = `url('/assets/images/icons/${this.iconName}.svg')`;
    }

    // 检测变更
    if (changes.isDetection || changes.petArchivesId) {
      this.fileApi = `${environment.SERVER.URL}/api/file?isDetection=${this.isDetection}&petArchivesId=${this.petArchivesId ? this.petArchivesId : ''}`;
    }
  }

  ngOnDestroy(): void {
    // 模态窗口回收
    if (this.previewImgModal) {
      this.previewImgModal.destroy();
    }
  }

  public onModelChange: (_: any) => void = (_: any) => { };
  public onModelTouched: (_: any) => void = (_: any) => { };

  writeValue(value: any): void {
    this.model = value;

    // 文件初始化
    if (value) {
      this.imgList = [{
        uid: '',
        name: '',
        status: 'done',
        url: `${environment.SERVER.URL}/${value}`,
        size: 0,
        type: '',
        response: {
          filePath: `${value}`
        }
      }];
    } else {
      this.imgList = [];
    }

    // 关闭摄像头
    this.isCamera = false;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/bmp';
      if (!isJPG) {
        this.message.error('仅支持上传 JPG、PNG、GIF、BMP类型的图片！');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.message.error('请上传小于10MB的图片！');
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.message.error('请上传宽高大于300x300的正方形图片！');
          observer.complete();
          return;
        }

        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        resolve(true);
        // resolve(width === height && width >= 300);
      };
    });
  }

  /**
   * 照片改变事件
   * @param info 照片信息
   */
  public photoChange(info: { file: NzUploadFile }): void {
    if (info.file.status === 'uploading') {
      return;
    }

    if (info.file.status === 'done') {
      if (info.file.response && (!this.isDetection || (info.file.response.recognition && info.file.response.recognition.status))) {
        this.changeValue(info.file.response.filePath);

        // 变更订阅
        this.onChanged.emit(info.file.response);
        // 不显示确认并上传，显示摄像头拍照
        this.isCamera = false;
        // 关闭视频流
        this.photoHelper.stopStream();
      } else {
        if (this.isDetection) {
          this.message.warning(info?.file?.response?.recognition?.message || '照片上传失败');
        } else {
          this.message.warning('照片上传失败，请重试');
        }
      }
    }
  }

  /**
   * 打开摄像头进行拍照
   * @param $event 句柄
   */
  public photo($event: any): void {
    try {
      this.model = null;
      this.imgList = [];
      this.showButton = false;
      this.isCamera = true;
      this.photoHelper.getMedia(this.videoPlayer.nativeElement);
    } catch (ex) {
      this.message.error('未检测到摄像头');

      this.isCamera = false;
    }
  }

  /**
   * 上传并显示照片
   * @param $event 句柄
   */
  public savePhoto($event: any): void {
    this.photoHelper.takeAndUpload(this.videoPlayer.nativeElement, this.isDetection, this.petArchivesId)
      .subscribe(response => {
        if ((this.isDetection && response && response.recognition.status) || (!this.isDetection && response)) {
          const fileId = response.fileName.split('.')[0];
          const imgType = response.fileName.split('.')[1];

          this.imgList = [{
            uid: fileId,
            name: response.fileName,
            status: 'done',
            url: `${environment.SERVER.URL}/${response.filePath}`,
            size: response.size,
            type: response.fileType,
            response: {
              filePath: response.filePath
            }
          }];

          this.isCamera = false;
          // 更新文件
          this.changeValue(response.filePath);
          // 变更订阅
          this.onChanged.emit(response);
          // 关闭视频流
          this.photoHelper.stopStream();
        } else {
          this.message.warning('拍照失败，请检查设备或者服务是否异常');
          this.isCamera = true;
        }
      });
  }

  /**
   * 值改变
   * @param model 图片路径
   */
  private changeValue(model: string): void {
    this.model = model;
    this.onModelChange(model);
  }
}
