<form nz-form [formGroup]="validateForm">
  <div nz-row>
    <div nz-col nzSm="24" nzMd="12" nzXl="8">
      <nz-form-item nzJustify="start" nzAlign="top">
        <nz-form-label class="form-label-100" nzRequired>费用标准</nz-form-label>
        <nz-form-control class="form-control-1" [nzErrorTip]="errorAmountReceived">
          <nz-input-number style="width: 100%;" formControlName="amountReceived" [nzMin]="0" [nzStep]="0.01"
            [nzPrecision]="2" [nzFormatter]="formatterRMB" [nzParser]="parserRMB" nzPlaceHolder="费用标准"
            [nzDisabled]="true" (ngModelChange)="amountModelChange($event)"></nz-input-number>
          <ng-template #errorAmountReceived let-control>
            <ng-container *ngIf="control.hasError('required')">
              请输入费用标准!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSm="24" [hidden]="!isShowInvoiceNo">
      <nz-form-item nzJustify="start" nzAlign="top">
        <nz-form-label class="form-label-100" nzFor="invoiceNo" [nzRequired]="required">发票号码</nz-form-label>
        <nz-form-control class="form-control-1" [nzErrorTip]="errorInvoiceNo">
          <nz-input-group [nzSuffix]="invoiceNoSuffixIcon">
            <input nz-input formControlName="invoiceNo" [readonly]="invoiceNoReadonly" placeholder="发票号码">
          </nz-input-group>
          <ng-template #errorInvoiceNo let-control>
            <ng-container *ngIf="control.hasError('required')">
              请输入发票号码!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<ng-template #invoiceNoSuffixIcon>
  <button nz-button nzType="primary" nzSearch [disabled]="!invoiceNoReadonly" (click)="invoinceClick($event)">
    <i nz-icon nzType="search"></i>
  </button>
</ng-template>
