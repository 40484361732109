<div class="login-container">
  <nz-spin [nzSpinning]="isSpinning" [nzSize]="'large'" nzTip="正在加载资源，请等待...">
    <div style="display: flex; flex-direction: column; height: 100%; width: 100%;">
      <div class="content-container">
        <div style="flex: 3; height: 100%; padding-top: 60px; text-align: center;">
          <img src="../../../assets/images/logo-login.png">
        </div>
        <div style="flex: 2;">
          <div class="form-container">
            <div style="text-align: center; padding-bottom: 10px; color: #00284C; font-size: 24px; font-weight: 600;">
              欢迎登录
            </div>
            <form nz-form class="login-form" [formGroup]="validateForm" (ngSubmit)="submit($event,validateForm.value)">
              <nz-form-item>
                <nz-form-control [nzErrorTip]="errorUserName">
                  <nz-input-group nzPrefixIcon="user">
                    <input type="text" nz-input formControlName="userName" placeholder="用户名或账号">
                  </nz-input-group>
                  <ng-template #errorUserName let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      请输入用户名或账号!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                      用户名最多可输入20个字符!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control [nzErrorTip]="errorPassword">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                      placeholder="密码" />
                  </nz-input-group>
                  <ng-template #errorPassword let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      请输入密码!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox formControlName="remember">
                    <span>记住我</span>
                  </label>
                  <!-- <a class="login-form-forgot" class="login-form-forgot">忘记密码</a> -->
                  <button nz-button class="login-form-button" [nzType]="'primary'"
                    [disabled]="!validateForm.valid">登录</button>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="footer-container">
    西安市公安局 ©2017 版权所有
  </div> -->

    </div>
  </nz-spin>
</div>

<ng-template #suffixTemplate>
  <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
</ng-template>