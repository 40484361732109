import { Component, OnInit, Input } from '@angular/core';
import { BreedDetailsModel } from 'src/app/canine-query/models/breed-details.model';
import { OwnerType } from '../../../shared/models/enum';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.less']
})
export class CardHeaderComponent implements OnInit {
  @Input() model: BreedDetailsModel = new BreedDetailsModel();

  ownerType: any = OwnerType;

  constructor() { }

  ngOnInit(): void {
  }

}
