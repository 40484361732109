import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { DogKindModel } from '../models/dog-kind.model';
import { DogKindParameter } from '../models/dog-kind-parameter';
import { DogKindSearchModel } from '../models/dog-kind-search.model';

import { BaseService } from '../../shared/base.service';
import { LabelValueModel } from '../../shared/models/label-value.model';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';

@Injectable({
  providedIn: 'root'
})
export class PetKindService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/petKind`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有犬种列表
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, string, number>>> {
    const url = `${this.apiUrl}/getall`;
    return this.http.get<ListResponse<KeyValueParentModel<string, string, number>>>(url);
  }

  /**
   * 获取机构分页集合
   * @param dogKindParameter 查询参数
   */
  queryDogKindPageList(dogKindParameter: DogKindParameter)
    : Observable<PagedResponse<DogKindSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(dogKindParameter);
    return this.http.get<PagedResponse<DogKindSearchModel>>(this.apiUrl, { params });
  }

  /**
   * 获取犬类犬种级联列表
   */
  getDogKindLabelValue(): Observable<ListResponse<LabelValueModel<string, string>>> {
    const url = `${this.apiUrl}/getlabelvalue`;
    return this.http.get<ListResponse<LabelValueModel<string, string>>>(url);
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  getDogKind(id: string): Observable<SingleResponse<DogKindModel>> {

    const url = `${this.apiUrl}/${id}`;

    return this.http.get<SingleResponse<DogKindModel>>(url);
  }

  /**
   * 新增机构
   * @param dogKind 机构实体
   */
  addDogKind(dogKind: DogKindModel): Observable<SingleResponse<DogKindModel>> {

    return this.http.post<SingleResponse<DogKindModel>>(this.apiUrl, dogKind, this.httpOptions);
  }

  /**
   * 更新犬种
   * @param dogKind 机构实体
   */
  updateDogKind(dogKind: DogKindModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${dogKind.id}`;

    return this.http.put<NocontentResponse>(url, dogKind, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除
   * @param id ID
   */
  deleteDogKind(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
