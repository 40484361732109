import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreedHistoryListParameter } from 'src/app/canine-query/models/breed-history-list.parameter';
import { BreedHistorySearchModel } from 'src/app/canine-query/models/breed-history-search.model';
import { BreedHistoryService } from 'src/app/canine-query/services/breed-history.service';
import { BusinessType } from 'src/app/shared/models/enum';

@Component({
  selector: 'app-inspection-record',
  templateUrl: './inspection-record.component.html',
  styleUrls: ['./inspection-record.component.less']
})
export class InspectionRecordComponent implements OnInit, OnChanges {

  @Input() registrationNo: string;
  @Input() type: BusinessType;

  public loading = false;
  public parameter: BreedHistoryListParameter = new BreedHistoryListParameter({ pageSize: 5 });

  public total = 0;
  public pageSizeOptions: any[] = [5, 10, 50, 100, 200];
  public dataSet: Array<BreedHistorySearchModel> = [];

  constructor(
    private breedHistoryService: BreedHistoryService,
  ) { }

  ngOnInit(): void {
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.registrationNo && changes.registrationNo.currentValue) {
      this.refreshData();
    }

    if (changes.type && changes.type.currentValue) {
      this.refreshData();
    }
  }

  refreshData(): void {
    this.dataSet = [];
    this.loading = true;
    if (!this.registrationNo || !this.type) {
      return;
    }

    this.parameter.init({
      registrationNo: this.registrationNo,
      businessType: this.type,
    });

    // 养犬历史
    this.breedHistoryService.paged(this.parameter).subscribe(response => {
      if (!response.didError) {
        // 数据集
        this.dataSet = response.model;
        // 总记录
        this.total = response.itemsCount;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  /**
   * 页索引改变事件
   * @param pageIndex 页索引
   */
  pageIndexChange(pageIndex: number): void {
    this.parameter.init({ pageIndex });
    // 加载列表数据
    this.refreshData();
  }

  /**
   * 分页大小改变事件
   * @param pageSize 分页大小
   */
  pageSizeChange(pageSize: number): void {
    this.parameter.init({ pageIndex: 1, pageSize });
    // 加载列表数据
    this.refreshData();
  }

  reset(): void {
    // 清空数据
    this.dataSet = [];
  }
}
