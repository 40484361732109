<div nz-row nzType="flex" nzAlign="top" nzJustify="space-around">
  <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="12">
    <div nz-row>
      <ng-container *ngIf="(model?.ownerType === this.ownerType.Company); else typeTemplate">
        <div nz-col nzSm="24" nzMd="12" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">单位名称：</p>
            <span [attr.title]="model?.ownerName">{{ model?.ownerName }}</span>
          </div>
        </div>
        <div nz-col nzSm="24" nzMd="12" nzXl="8">
          <div class="item-wrap">
            <p class="label">统一社会信用代码：</p>
            <span [attr.title]="model?.unifiedCode">{{model?.unifiedCode}}</span>
          </div>
        </div>
        <div nz-col nzSm="24" nzMd="12" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">养犬人姓名：</p>
            <span [attr.title]="model?.breedName">{{model?.breedName}}</span>
          </div>
        </div>
      </ng-container>
      <ng-template #typeTemplate>
        <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">犬主姓名：</p>
            <span [attr.title]="model?.ownerName">{{ model?.ownerName }}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">性别：</p>
            <span [attr.title]="model?.ownerGender">{{model?.ownerGender | dictName:'Gender' | async}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">民族：</p>
            <span [attr.title]="model?.nationalId">{{model?.nationalId | nationalName | async}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">残疾等级：</p>
            <span [attr.title]="model?.disabledType">{{model?.disabledType | dictName:'DisabledType' | async}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
          <div class="item-wrap">
            <p class="label-90">残疾证号码：</p>
            <span [attr.title]="model?.disabledCertificateNo">{{model?.disabledCertificateNo}}</span>
          </div>
        </div>
      </ng-template>

      <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
        <div class="item-wrap">
          <p class="label-90">电话号码：</p>
          <span [attr.title]="model?.telephone">{{model?.telephone}}</span>
        </div>
      </div>

      <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
        <div class="item-wrap">
          <p class="label-90">证件类型：</p>
          <span [attr.title]="model?.certificateType">{{model?.certificateType | dictName:'DocumentType' |
            async}}</span>
        </div>
      </div>

      <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
        <div class="item-wrap">
          <p class="label-90">证件号码：</p>
          <span [attr.title]="model?.certificateNo">{{model?.certificateNo}}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="8">
    <div nz-row>
      <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
        <div class="item-wrap">
          <p class="label-90">证件正面照：</p>
          <img *ngIf="(model && model.certificateFrontImg)" [src]="model.certificateFrontImg | imgSrc"
            (click)="showImage(model.certificateFrontImg)" style="cursor: pointer; width: 88px; height: 60px;" />
        </div>
      </div>

      <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
        <div class="item-wrap">
          <p class="label-90">证件背面照：</p>
          <img *ngIf="(model && model.certificateBackImg)" [src]="model.certificateBackImg | imgSrc"
            (click)="showImage(model.certificateBackImg)" style="cursor: pointer;width: 88px; height: 60px;" />
        </div>
      </div>
      <ng-container *ngIf="(model?.ownerType === this.ownerType.Personal);">
        <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
          <div nz-row>
            <div class="item-wrap">
              <p class="label-90">残疾证明：</p>
              <img *ngIf="(model && model.disabledCertificateImg)" [src]="model.disabledCertificateImg | imgSrc"
                (click)="showImage(model.disabledCertificateImg)" style="cursor: pointer; width: 88px; height: 60px;" />
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- 电子犬证 -->
  <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="4">
    <div nz-row>
      <div nz-col style="min-height: 65px;">
        <div class="item-wrap">
          <p class="label-90">电子犬证：</p>
          <img *ngIf="(model && model.certificateImg)" [src]="model.certificateImg | imgSrc"
            (click)="showImage(model.certificateImg)" style="cursor: pointer; width: 88px; height: 60px;" />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent>
  <img *ngIf="!!previewUrl" [src]="previewUrl" [ngStyle]="{ width: '100%' }" />
</ng-template>
