import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';

import { NoPermissionComponent } from './pages/no-permission/no-permission.component';

// 路由信息
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          breadcrumb: '驾驶舱'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'canine-manage',
        loadChildren: () => import('../canine-manage/canine-manage.module').then(m => m.CanineManageModule),
        data: {
          breadcrumb: '犬管中心'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'canine-query',
        loadChildren: () => import('../canine-query/canine-query.module').then(m => m.CanineQueryModule),
        data: {
          breadcrumb: '犬类查询'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'report-manage',
        loadChildren: () => import('../report-manage/report-manage.module').then(m => m.ReportManageModule),
        data: {
          breadcrumb: '报表管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'info-manage',
        loadChildren: () => import('../info-manage/info-manage.module').then(m => m.InfoManageModule),
        data: {
          breadcrumb: '信息管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'system-manage',
        loadChildren: () => import('../system-manage/system-manage.module').then(m => m.SystemManageModule),
        data: {
          breadcrumb: '系统管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'no-permission',
        component: NoPermissionComponent,
        data: {
          breadcrumb: '无权限访问'
        }
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: '登录'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FrameRoutingModule { }
