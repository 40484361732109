import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ng-zorro-antd
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

// common
import { BottomPanelComponent } from './components/bottom-panel/bottom-panel.component';
import { CameraImageComponent } from './components/camera-image/camera-image.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { IconComponent } from './components/icon/icon.component';
import { TitleComponent } from './components/title/title.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';

// business
import { InspectionRecordComponent } from './components/business/inspection-record/inspection-record.component';
import { OwnerDetailsComponent } from './components/business/owner-details/owner-details.component';
import { PetDetailsComponent } from './components/business/pet-details/pet-details.component';
import { PrintComponent } from './components/business/print/print.component';
import { ReasonComponent } from './components/business/reason/reason.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { InspectionEditComponent } from '../canine-manage/components/inspection-edit/inspection-edit.component';
import { ChargeComponent } from '../canine-manage/components/charge/charge.component';

@NgModule({
  declarations: [
    // common
    BottomPanelComponent,
    CameraImageComponent,
    CardHeaderComponent,
    IconComponent,
    TitleComponent,
    ToolboxComponent,
    // business
    InspectionRecordComponent,
    OwnerDetailsComponent,
    PetDetailsComponent,
    PrintComponent,
    ReasonComponent,
    InspectionEditComponent,
    ChargeComponent,
  ],
  entryComponents: [
    BottomPanelComponent,
    CameraImageComponent,
    CardHeaderComponent,
    IconComponent,
    TitleComponent,
    ToolboxComponent,
    // business
    InspectionRecordComponent,
    OwnerDetailsComponent,
    PetDetailsComponent,
    PrintComponent,
    ReasonComponent,
    InspectionEditComponent,
    ChargeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // ng-zorro
    NzGridModule,
    NzMessageModule,
    NzModalModule,
    NzStepsModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzUploadModule,
    NzCardModule,
    NzDrawerModule,
    NzFormModule,
    NzListModule,
    NzTableModule,
    NzPaginationModule,
    NzAutocompleteModule,
    NzInputNumberModule,
    NzDatePickerModule,
    // customer
    PipesModule.forRoot(),
  ],
  exports: [
    BottomPanelComponent,
    CameraImageComponent,
    CardHeaderComponent,
    IconComponent,
    TitleComponent,
    ToolboxComponent,
    // business
    InspectionRecordComponent,
    OwnerDetailsComponent,
    PetDetailsComponent,
    PrintComponent,
    ReasonComponent,
    InspectionEditComponent,
    ChargeComponent,
  ]
})
export class BasisModule {
  static forRoot(): any {
    return {
      ngModule: BasisModule,
      providers: [],
    };
  }
}
