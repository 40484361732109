import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { NationalStorageService } from '../storages/national-storage.service';
import { KeyValueModel } from '../models/key-value.model';

@Pipe({
  name: 'nationalEffective'
})
export class NationalEffectivePipe implements PipeTransform {

  constructor(
    private nationalStorageService: NationalStorageService
  ) { }

  transform(include?: string): any {
    return this.nationalStorageService.getEffectiveStorage(include).pipe(
      map((data: Array<KeyValueModel<string, string>>, index: number) => {
        return data.map(x => ({ key: x.key, value: x.value }));
      })
    );
  }

}
