import { Component, OnInit, OnDestroy } from '@angular/core';
import { IMessage } from '@stomp/stompjs';
import { Subscription, Observable, of } from 'rxjs';
import { RxStompService } from '@stomp/ng2-stompjs';
import { mergeMap } from 'rxjs/operators';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { RemindService } from '../../services/remind.service';
import { ListResponse } from '../../../shared/models/list-response';
import { RemindSearchModel } from '../../models/remind-search.model';
import { DealStatus } from '../../../shared/models/enum';
import { InfoSubSearchModel } from 'src/app/info-manage/models/info-sub-search.model';
import { InfoSentDetailsComponent } from 'src/app/info-manage/components/info-sent-details/info-sent-details.component';

@Component({
  selector: 'app-nav-notice',
  templateUrl: './nav-notice.component.html',
  styleUrls: ['./nav-notice.component.less']
})
export class NavNoticeComponent implements OnInit, OnDestroy {

  private demo: Subscription;
  private submission: Subscription;
  private submissionAct: Subscription;
  private informationSentDetailsModal: NzModalRef<InfoSentDetailsComponent, any>;

  initLoading = false;
  isVisible = this.count > 0;
  noticeCount = 0;
  messageCount = 0;
  toBeDoneCount = 0;
  noticeList: Array<any> = [];
  messageList: Array<any> = [];
  toBeDoneList: Array<any> = [];

  get count(): number {
    return this.noticeCount + this.messageCount + this.toBeDoneCount;
  }
  get noticeTitle(): string {
    return `通知（${this.noticeCount}）`;
  }
  get messageTitle(): string {
    return `消息（${this.messageCount}）`;
  }
  get toBeDoneTitle(): string {
    return `待办（${this.toBeDoneCount}）`;
  }

  public receivedMessages: Array<any> = [];

  constructor(
    private modalService: NzModalService,
    private rxStompService: RxStompService,
    private userResourceStorageService: UserResourceStorageService,
    private remindService: RemindService
  ) { }

  ngOnInit(): void {

    this.refreshData();

    this.demo = this.rxStompService.watch('/topic/demo')
      .subscribe((message: IMessage) => {
        this.receivedMessages.push(message.body);
      });

    // 注册消息
    this.submission = this.onReceivedSubmission()
      .subscribe((message: IMessage) => {
        // 处理
        this.refreshData();
      });
  }

  ngOnDestroy(): void {
    if (this.demo) {
      this.demo.unsubscribe();
    }
    if (this.submission) {
      this.submission.unsubscribe();
    }
    if (this.submissionAct) {
      this.submissionAct.unsubscribe();
    }
    if (this.modalService) {
      this.modalService.closeAll();
    }
    if (this.informationSentDetailsModal) {
      this.informationSentDetailsModal.destroy();
    }
  }

  onSendMessage(): void {
    const message = `Message generated at ${new Date()}`;
    this.rxStompService.publish({ destination: '/topic/demo', body: message });
  }

  public publishMessage($event: any): void {
  }

  public publishMessageAct($event: any): void {
  }

  /**
   * 刷新通知公告
   */
  private refreshData(): void {
    // 通知
    this.remindService.getNotice().subscribe((response: ListResponse<RemindSearchModel>) => {
      if (!response.didError) {
        this.noticeList = new Array<any>();
        if (response.model) {
          response.model.forEach(x => {
            this.noticeList.push({
              id: x.id,
              caption: x.title,
              status: this.statusConvert(x.state)
            });
          });
        }

        this.noticeCount = response.model.length;
      }
    });

    this.remindService.getNews().subscribe((response: ListResponse<RemindSearchModel>) => {
      if (!response.didError) {
        this.messageList = new Array<any>();
        if (response.model) {
          response.model.forEach(x => {
            this.messageList.push({
              id: x.id,
              caption: x.title,
              status: this.statusConvert(x.state)
            });
          });
        }

        this.messageCount = response.model.length;
      }
    });

    this.remindService.getTodo().subscribe((response: ListResponse<RemindSearchModel>) => {
      if (!response.didError) {
        this.toBeDoneList = new Array<any>();
        if (response.model) {
          response.model.forEach(x => {
            this.toBeDoneList.push({
              id: x.id,
              caption: x.title,
              status: this.statusConvert(x.state)
            });
          });
        }

        this.toBeDoneCount = response.model.length;
      }
    });


  }

  /**
   * 发送报送消息
   */
  private onSendSubmission(data: any): Subscription {
    // 获取当前登录用户信息
    // 获取当前登录用户信息
    return this.userResourceStorageService.getUser().subscribe(
      (user: any) => {
        // 判断用户及机构是否存在
        if (user && user.organizationCode) {
          const organizationCode: string = user.organizationCode;
          // 路由
          const routingKey = organizationCode.split('/').join('.');

          const dataStr = JSON.stringify(data);

          // messages published to the default exchange are given the destination /queue/queuename
          // messages published to amq.topic are given the destination /topic/routing_key;
          // all other messages are given the destination /exchange/exchange_name[/routing_key]
          this.rxStompService.publish({ destination: `/exchange/topic.ex_pets.submission/${routingKey}`, body: dataStr });
        }
      });
  }

  /**
   * 接收推送消息
   */
  private onReceivedSubmission(): Observable<IMessage> {
    // 获取当前登录用户信息
    return this.userResourceStorageService.getUser().pipe(
      mergeMap(
        (user: any, index: number) => {
          // 判断用户是否存在
          if (user) {
            const routingKey = user.userId;

            // return this.rxStompService.watch(`/exchange/topic.ex_pets.submission/${routingKey}.*`);
            return this.rxStompService.watch(`/exchange/topic.ex_pets.submission/${routingKey}`);
          }

          return of(null);
        }
      )
    );
  }

  /**
   * 处理消息
   * @param $event 句柄
   * @param id 消息ID
   */
  reminderDealClick($event: any, id: string | Array<any>): void {
    const ids = new Array<string>();
    if (typeof (id) === 'string') {
      ids.push(id);
    } else {
      id.forEach(x => ids.push(x.id));
    }

    this.remindService.read(ids).subscribe(
      (response) => {
        if (response.didError) {

        } else {
          // 刷新列表
          this.refreshData();
        }
      }
    );
  }

  /**
   * 状态解析
   * @param status 状态
   */
  private statusConvert(status: number): string {
    switch (status) {
      case DealStatus.UnKnown:
        return '待处理';
      case DealStatus.PendingDeal:
        return '待处理';
      case DealStatus.Agree:
        return '同意';
      case DealStatus.Refused:
        return '拒绝';
      case DealStatus.Confirm:
        return '已确认';
    }
  }

  /**
   * 查看详情
   */
  private details(id: string): void {
    const model: InfoSubSearchModel = null;
    this.informationSentDetailsModal = this.modalService.create({
      nzTitle: '信息详情',
      nzWidth: '800px',
      nzContent: InfoSentDetailsComponent,
      nzComponentParams: {
        id,
        model
      },
      nzFooter: [{
        label: '已读',
        type: 'primary', // 类型
        onClick: () => {
          this.reminderDealClick(event, id);
          this.informationSentDetailsModal.close();
        }
      },
      {
        label: '关闭',
        onClick: () => {
          this.informationSentDetailsModal.close();
        }
      }]
    });
  }

  /**
   * 关闭弹出层
   */
  cancelEvent(): void {
    this.modalService.closeAll();
  }
}
