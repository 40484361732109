import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationStorageService } from '../storages/organization-storage.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'organizationName'
})
export class OrganizationNamePipe implements PipeTransform {

  constructor(private organizationStorageService: OrganizationStorageService) {

  }

  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.organizationStorageService.getName(value);
  }
}
