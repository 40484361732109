import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PagedResponse } from 'src/app/shared/models/paged-response';
import { SingleResponse } from 'src/app/shared/models/single-response';

import { BaseService } from 'src/app/shared/base.service';

import { BreedHistorySearchModel } from '../models/breed-history-search.model';
import { BreedHistoryPagedParameter } from '../models/breed-history-paged.parameter';
import { ListResponse } from 'src/app/shared/models/list-response';
import { BreedHistoryListParameter } from '../models/breed-history-list.parameter';

@Injectable({
  providedIn: 'root'
})
export class BreedHistoryService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/breedHistory`;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 获取分页列表
   * @param parameter 查询参数
   */
  paged(parameter: BreedHistoryPagedParameter): Observable<PagedResponse<BreedHistorySearchModel>> {
    const url = `${this.apiUrl}`;
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<BreedHistorySearchModel>>(url, { params });
  }

  /**
   * 获取列表
   * @param parameter 查询参数
   */
  list(parameter: BreedHistoryListParameter): Observable<ListResponse<BreedHistorySearchModel>> {
    const url = `${this.apiUrl}/list`;
    const params = this.removeEmptyProps(parameter);
    return this.http.get<ListResponse<BreedHistorySearchModel>>(url, { params });
  }

  /**
   * 获取详情
   * @param no 养犬登记号
   */
  details(no: string): Observable<SingleResponse<BreedHistorySearchModel>> {
    const url = `${this.apiUrl}/${no}`;
    return this.http.get<SingleResponse<BreedHistorySearchModel>>(url);
  }

}
