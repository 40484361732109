import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KeyValue } from '@angular/common';
import { DictionaryStorageService } from '../storages/dictionary-storage.service';

@Pipe({
  name: 'dictEffective'
})
export class DictEffectivePipe implements PipeTransform {

  constructor(
    private dictionaryStorageService: DictionaryStorageService
  ) { }

  transform(value: string, include?: number): Observable<Array<KeyValue<string, number>>> {
    if (!value) {
      return of([]);
    }

    return this.dictionaryStorageService.getEffectiveItems(value, include);
  }
}
