export class ChargeStrategyModel {
    /**
     * ID
     */
    public id?: string;

    /**
     * 策略名称
     */
    public name?: string;

    /**
     * 收费项类型
     */
    public chargeItem?: number;

    /**
     * 是否残疾
     */
    public isDisabled?: number;

    /**
     * 限养区类型
     */
    public areaType?: number;

    /**
     * 是否绝育
     */
    public isNeutered?: number;

    /**
     * 收费类型
     */
    public chargeType?: number;

    /**
     * 费用标准
     */
    public amount?: number;

    /**
     * 是否删除
     */
    public dataStatus?: number;

    /**
     * 行版本
     */
    public rowVersion?: Date;

    /**
     * 构造器
     * @param init 初始化对象
     */
    constructor(init?: Partial<ChargeStrategyModel>) {
        Object.assign(this, init);
    }

}
