import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.less']
})
export class ReasonComponent implements OnInit, OnChanges {
  @Input() model?: any;
  @Input() options?: Array<any> = [];

  public validateForm: FormGroup;
  public placeHolder = '说明信息';

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
    if (!this.model) {
      this.model = {};
    }

    // 表单初始化
    this.validateForm = this.fb.group({
      // 说明
      remark: [this.model?.remark, [Validators.required, Validators.maxLength(200)]]
    });

    // 吊销为空
    if (this.options === null || this.options.length === 0) {
      this.placeHolder = '请描述吊销原因!';
    }
  }

  /**
   * 变更实现
   * @param changes 变更
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      this.model = changes.model.currentValue;

      this.validateForm.patchValue({
        // 说明原因
        remark: this.model.remark
      });
    }
  }

  get valid(): boolean {
    if (!this.validateForm.valid) {
      return false;
    }

    return true;
  }

  /**
   * 原因说明预提交
   */
  public preSubmit(): any {
    // 初始化数据
    let remark = null;
    const formData = this.validateForm.getRawValue();

    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    if (!this.validateForm.valid) {
      return remark;
    }

    remark = { ...formData };

    return remark;
  }

  /**
   * 重置表单
   * @param e 鼠标事件
   */
  public reset(): void {
    this.validateForm.reset();

    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }
}
