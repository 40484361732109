/**
 * 禁养查询模型
 */
export class ProhibitionStrategySearchModel {
    /**
     * 主键
     */
    id?: string;

    /**
     * 限养区类型
     */
    areaType?: number;

    /**
     * 犬种ID
     */
    petKindId?: string;

    /**
     * 犬类
     */
    petType?: number;

    /**
     * 创建人
     */
    createdBy?: string;

    /**
     * 创建人名称
     */
    createdByName?: string;

    /**
     * 创建时间
     */
    created?: Date;

    /**
     * 最后修改人
     */
    lastModifiedBy?: string;

    /**
     * 最后修改人姓名
     */
    lastModifiedByName?: string;

    /**
     * 最后修改时间
     */
    lastModified?: Date;

    /**
     * 行版本
     */
    rowVersion?: Date;

    /**
     * 构造器
     * @param init 初始化对象
     */
    constructor(init?: Partial<ProhibitionStrategySearchModel>) {
        Object.assign(this, init);
    }
}
