import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationStorageService } from '../storages/organization-storage.service';
import { Observable, of } from 'rxjs';
import { KeyValueParentModel } from '../models/key-value-parent.model';

@Pipe({
  name: 'organizationEffective'
})
export class OrganizationEffectivePipe implements PipeTransform {

  constructor(private organizationStorageService: OrganizationStorageService) { }

  transform(parentId?: string, exceptId?: string, includeId?: string): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.organizationStorageService.getEffectiveTree(parentId, exceptId, includeId);
  }
}
