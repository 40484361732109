import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LabelValueModel } from '../models/label-value.model';
import { PetKindStorageService } from '../storages/pet-kind-storage.service';

@Pipe({
  name: 'petKindEffective'
})
export class PetKindEffectivePipe implements PipeTransform {

  constructor(
    private petKindStorageService: PetKindStorageService
  ) { }

  transform(exceptNonChild?: boolean, disabledDogKindes?: Array<string>, include?: string)
    : Observable<Array<LabelValueModel<string, string>>> {
    return this.petKindStorageService.getEffectiveTree(exceptNonChild, disabledDogKindes, include);
  }
}
