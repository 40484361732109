import { PagedParameters } from '../../shared/models/paged-parameters';

export class BreedPagedParameter extends PagedParameters {
    /**
     * 犬主姓名
     */
    public ownerName?: string;

    /**
     * 犬主性别
     */
    public ownerGender?: number;

    /**
     * 民族
     */
    public nationalId?: string;

    /**
     * 证件号码
     */
    public certificateNo?: string;

    /**
     * 电话号码
     */
    public telephone?: string;

    /**
     * 犬名
     */
    public petName?: string;

    /**
     * 犬性别
     */
    public petGender?: number;

    /**
     * 犬种
     */
    public petKindId?: string;

    /**
     * 犬类
     */
    public petType?: number;

    /**
     * 毛色
     */
    public hairColor?: string;

    /**
     * 养犬地址
     */
    public address?: string;

    /**
     * 辖区编码
     */
    public areaCode?: string;

    /**
     * 限养区类型
     */
    public areaType?: number;

    /**
     * 模糊查询条件，支持犬登记编码，
     */
    public likeCondition?: string;

    /**
     * 电子标识码
     */
    public tagNo?: string;

    /**
     * 登记的开始时间
     */
    public registerStartTime?: string;

    /**
     * 登记的结束时间
     */
    public registerEndTime?: string;

    /**
     * 制卡标志
     * 1：已制卡；2：未制卡
     */
    public cardFlag?: number;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init?: Partial<BreedPagedParameter>) {
        super();

        Object.assign(this, init);
    }

    /**
     * 初始化数据
     * @param init 初始化
     */
    init(init?: Partial<BreedPagedParameter>): void {
        Object.assign(this, init);
    }
}
