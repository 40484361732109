<div class="search-result-list">
  <nz-table #basicTable nzSize="middle" class="fixed-table" [nzLoading]="loading" [nzData]="dataSet"
    [nzFrontPagination]="false" [nzTotal]="total" [nzPageIndex]="parameter.pageIndex" [nzPageSize]="parameter.pageSize"
    (nzPageIndexChange)="pageIndexChange($event)" [nzShowTotal]="totalTemplate" [nzScroll]="{ x:'600px' }">
    <ng-template #totalTemplate let-total>共 {{total}} 条</ng-template>
    <thead>
      <tr>
        <th nzWidth="130px">登记编码</th>
        <th nzWidth="100px">犬名</th>
        <th nzWidth="110px">免疫日期</th>
        <th nzWidth="110px">免疫编号</th>
        <th nzWidth="110px">年检日期</th>
        <th nzWidth="110px">应年检日期</th>
        <th nzWidth="100px">经办人</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data?.registrationNo }}</td>
        <td>{{ data?.petName }}</td>
        <td>{{ data?.immunityDate | dateLimit | date: 'yyyy-MM-dd' }}</td>
        <td>{{ data?.immunityNo }}</td>
        <td>{{ data.annualInspectionDate | dateLimit | date: 'yyyy-MM-dd' }}</td>
        <td>{{ data.needAnnualInspectionDate | dateLimit | date: 'yyyy-MM-dd' }}</td>
        <td>{{ data?.createdByName }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>