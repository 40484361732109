import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { BaseService } from '../../shared/base.service';

import { PagedResponse } from '../../shared/models/paged-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { UserInputModel } from '../models/user-input.model';
import { UserParameter } from '../models/User-parameter';
import { UserSearchModel } from '../models/User-search.model';
import { UserDetailsModel } from '../models/user-details.model';
import { ChangePasswordModel } from '../models/change-password-model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  // URL to web api
  private userUrl = `${this.apiUrlBase}/api/user`;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 获取用户分页集合
   * @param userParameter 查询参数
   */
  queryPageList(userParameter: UserParameter)
    : Observable<PagedResponse<UserSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(userParameter);
    return this.http.get<PagedResponse<UserSearchModel>>(this.userUrl, { params })
      .pipe(
        tap(
          (response: PagedResponse<UserSearchModel>) => {
            this.log(``);
          }
        )
      );
  }

  /**
   * 获取用户详情
   * @param id 用户ID
   */
  get(id: string): Observable<SingleResponse<UserDetailsModel>> {

    const url = `${this.userUrl}/${id}`;

    return this.http.get<SingleResponse<UserDetailsModel>>(url);
  }

  /**
   * 新增用户
   * @param user 用户实体
   */
  add(user: UserInputModel): Observable<SingleResponse<UserInputModel>> {

    return this.http.post<SingleResponse<UserInputModel>>(this.userUrl, user, this.httpOptions);
  }

  /**
   * 更新用户信息
   * @param user 用户实体
   */
  update(user: UserInputModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.userUrl}/${user.id}`;

    return this.http.put<NocontentResponse>(url, user, this.httpOptions);
  }

  /**
   * 修改用户密码
   * @param model 修改密码模型
   */
  patch(model: ChangePasswordModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.userUrl}`;

    return this.http.patch<NocontentResponse>(url, model, this.httpOptions);
  }

  /**
   * 重置用户密码
   * @param id 用户ID
   */
  resetPassword(id: string): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.userUrl}/resetpassword/${id}`;

    return this.http.patch<NocontentResponse>(url, null, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.userUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除用户
   * @param id 用户ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.userUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 获取当前用户所拥有的资源
   */
  userResource(): Observable<SingleResponse<any>> {
    const url = `${this.userUrl}/userResource`;

    return this.http.get<SingleResponse<any>>(url, this.httpOptions);
  }
}
