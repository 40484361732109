import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/base.service';
import { ListResponse } from 'src/app/shared/models/list-response';
import { PagedResponse } from 'src/app/shared/models/paged-response';
import { SingleResponse } from 'src/app/shared/models/single-response';
import { InvoiceParameter } from '../models/invoice-parameter';
import { InvoiceSearchModel } from '../models/invoice-search.model';
import { InvoiceInputModel } from '../models/invoice-input.model';
import { InvoiceBatchInputModel } from '../models/invoice-batch-input.model';
import { InvoiceByAmountViewModel } from '../models/invoice-by-amount-view.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/invoice`;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 分页列表
   * @param parameter 查询参数
   */
  paged(parameter: InvoiceParameter): Observable<PagedResponse<InvoiceSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<InvoiceSearchModel>>(this.apiUrl, { params });
  }

  /**
   * 获取发票号码
   * @param amount 金额
   */
  getByAmount(amount?: number): Observable<ListResponse<InvoiceByAmountViewModel>> {
    const url = `${this.apiUrl}/getByAmount?amount=${amount}`;
    return this.http.get<ListResponse<InvoiceByAmountViewModel>>(url);
  }

  /**
   * 新增
   * @param inputModel 输入模型
   */
  add(inputModel: InvoiceBatchInputModel): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}/batch`;
    // 参数处理
    return this.http.post<SingleResponse<number>>(url, inputModel, this.httpOptions);
  }

  /**
   * 更新
   * @param id ID
   * @param inputModel 输入模型
   */
  update(id?: string, inputModel?: InvoiceInputModel): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}/${id}`;
    // 参数处理
    return this.http.put<SingleResponse<number>>(url, inputModel, this.httpOptions);
  }

  /**
   * 删除
   * @param id ID
   */
  delete(id?: string): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}/${id}`;
    // 参数处理
    return this.http.delete<SingleResponse<number>>(url);
  }
  /**
   * 删除
   * @param id ID
   */
  batchDelete(ids?: any): Observable<SingleResponse<number>> {

    const url = `${this.apiUrl}/batchDelete`;
    // 参数处理
    return this.http.post<SingleResponse<any>>(url, ids);
  }
}
