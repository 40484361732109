<nz-card style="background-color: #e6f7ff; border: 1px solid #91d5ff; border-radius: 4px; margin-bottom: 8px;">
  <div style="display: flex; align-items: center; flex-wrap: wrap;">
    <div>
      <p class="label form-label-100">犬登记编码：</p>
      <span [attr.title]="model?.registrationNo">{{model?.registrationNo}}</span>
    </div>
    <div>
      <p class="label form-label-100">电子标识码：</p>
      <span [attr.title]="model?.tagNo">{{model?.tagNo}}</span>
    </div>
    <ng-container *ngIf="(model?.ownerType === this.ownerType.Company); else typeTemplate">
      <div>
        <p class="label form-label-100">单位名称：</p>
        <span [attr.title]="model?.ownerName">{{model?.ownerName}}</span>
      </div>
      <div>
        <p class="label form-label-100">养犬人：</p>
        <span [attr.title]="model?.breedName">{{model?.breedName}}</span>
      </div>
    </ng-container>
    <ng-template #typeTemplate>
      <div>
        <p class="label form-label-100">犬主：</p>
        <span [attr.title]="model?.ownerName">{{model?.ownerName}}</span>
      </div>
    </ng-template>
    <div>
      <p class="label form-label-100">犬名：</p>
      <span [attr.title]="model?.petName">{{model?.petName}}</span>
    </div>
    <div>
      <p class="label form-label-100">登记日期：</p>
      <span [attr.title]="model?.created">{{model?.created | date:'yyyy-MM-dd'}}</span>
    </div>
  </div>
</nz-card>