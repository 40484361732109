import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../shared/base.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { IndexStorageService } from '../../shared/storages/index-storage.service';
import { SingleResponse } from '../../shared/models/single-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  // URL to web api
  private authUrl = `${this.apiUrlBase}/api/auth`;

  private authSubject: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private message: NzMessageService,
    private localStorageService: LocalStorageService,
    private indexStorageService: IndexStorageService
  ) {
    super();

    this.authSubject = new BehaviorSubject<any>(this.getAuthStorage());
  }

  public get authValue(): any {
    return this.authSubject.value;
  }

  /**
   * 登录
   * @param loginModel 登录参数
   */
  login(loginModel: any): Observable<SingleResponse<any>> {
    // 授权相关数据
    loginModel.client_id = environment.JWTToken.client_id;
    loginModel.client_secret = environment.JWTToken.client_secret;
    loginModel.grant_type = environment.JWTToken.grant_type;

    return this.http.post<any>(this.authUrl, loginModel, this.httpOptions)
      .pipe(
        tap(
          (response: SingleResponse<any>) => {
            if (!response.didError) {
              this.setAuthStorage(response.model);
              // 授权数据
              this.authSubject.next(response.model);
            }
          }
        )
      );
  }

  refreshToken(): Observable<any> {
    // URL to web api
    const url = `${this.apiUrlBase}/api/token/refresh`;

    const auth: any = this.getAuthStorage();
    if (!auth) {
      return of(null);
    }

    const refreshTokenModel: any = {
      // 客户端ID
      clientId: environment.JWTToken.client_id,
      // 签名
      signingKey: environment.JWTToken.client_secret,
      // token
      token: auth.access_token,
      // 刷新token
      refreshToken: auth.refresh_token
    };

    return this.http.post<any>(url, refreshTokenModel, this.httpOptions)
      .pipe(
        tap(
          (response: any) => {
            if (response) {
              this.setAuthStorage(response);
              // 授权数据
              this.authSubject.next(response);
            }
          }
        )
      );
  }

  /**
   * 退出登录
   */
  logout(): void {
    this.clearResource();
    // 跳转到登录页面
    this.router.navigate(['/login']);
  }

  /**
   * 清空资源
   */
  clearResource(): void {
    // 清空授权数据
    this.authSubject.next(null);
    // 清空所有缓存
    this.indexStorageService.clearStorage();
    // 清空授权
    this.clearAuthStorage();
  }

  /**
   * 设置授权缓存
   * @param source 数据源
   */
  private setAuthStorage(source: any): void {
    this.localStorageService.set('AUTH', source);
    this.localStorageService.set('TOKEN', source.access_token);
  }

  private clearAuthStorage(): void {
    this.localStorageService.remove('AUTH');
    this.localStorageService.remove('TOKEN');
  }

  /**
   * 获取授权缓存
   */
  private getAuthStorage(): any {
    return this.localStorageService.get('AUTH');
  }
}
