import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';

import { AuthService } from '../frame/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private message: NzMessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 0:
            if (err.statusText === 'Unknown Error') {
              this.message.error('无法访问服务，请检查服务是否已正常启动！');
            }
            return throwError(err);
          case 400:
            // 重置状态
            this.isRefreshingToken = false;
            this.handle400Error(err);
            return throwError(err);
          case 401:
            return this.handleUnauthorized(request, next);
          case 422:
            // 重置状态
            this.isRefreshingToken = false;
            const error = err.error || err.statusText;
            return throwError(err);
          default:
            // 重置状态
            this.isRefreshingToken = false;
            // 异常消息提示
            this.message.error(
              err && err.error && err.error.errorMessage ?
                err.error.errorMessage : err.statusText
            );

            return throwError(err);
        }
      } else {
        // 重置状态
        this.isRefreshingToken = false;
        return observableThrowError(err);
      }
    }));
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  handle400Error(error): void {
    // 未授权，重新登录
    // this.authService.logout();
    // location.reload(true);
  }

  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    try {
      // 判断是否正在执行刷新token
      if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);

        // get a new token via userService.refreshToken
        return this.authService.refreshToken()
          .pipe(
            switchMap((response: any) => {
              if (req && response) {
                this.tokenSubject.next(response);
                req = this.addToken(req, response.access_token);
                return next.handle(req);
              } else {
                // If we don't get a new token, we are in trouble so logout.
                this.authService.logout();
                // location.reload(true);
                return of(null);
              }
            }),
            catchError(error => {
              this.isRefreshingToken = false;
              // If we don't get a new token, we are in trouble so logout.
              this.authService.logout();
              // location.reload(true);
              return observableThrowError(error);
            }),
            finalize(() => {
              this.isRefreshingToken = false;
            })
          );
      } else {
        return this.tokenSubject
          .pipe(
            filter(response => response != null),
            take(1),
            switchMap(response => {
              if (req && response) {
                req = this.addToken(req, response.access_token);
                return next.handle(req);
              } else {
                this.authService.logout();
              }
            })
          );
      }
    } catch (ex) {
      this.isRefreshingToken = false;
      // If we don't get a new token, we are in trouble so logout.
      this.authService.logout();
    } finally {
    }
  }
}
