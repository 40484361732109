<nz-steps [nzCurrent]="current" [nzStatus]="currentStepStatus">
  <nz-step *ngFor="let item of stepArray" [nzTitle]="item.title" [nzDescription]="item.description">
  </nz-step>
</nz-steps>

<template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="ctx"></template>

<!-- 确认信息 -->
<ng-template #confirm let-registrationNo="registrationNo">
  <div style="min-height: 230px;">
    <div style="text-align: center; padding-top: 24px;">
      <nz-avatar [nzSize]="64" nzIcon="check" style="background-color:#52c41a;"></nz-avatar>
      <div style="padding: 12px; font-size: 24px">{{message}}</div>
      <div style="display: inline-block; text-align: left;">
        <div>点击“ <span style="color:#52c41a;">写卡/打印</span> ”按钮，执行打印及写卡流程</div>
        <div>点击“ <span style="color:darkred;">继续登记</span> ”按钮，跳过打印、写卡流程并结束</div>
      </div>
    </div>

    <div class="steps-content">
      <div nz-row>
        <div nz-col nzSm="24">
          <div class="item-wrap">
            <p class="label">养犬登记证号码：</p>
            <span style="text-align: left;" [attr.title]="cardNo">{{cardNo}}</span>
          </div>
        </div>

        <ng-container *ngIf="(breedModel && breedModel.ownerType === ownerType.Personal); else typeTemplate">
          <div nz-col nzSm="24">
            <div class="item-wrap">
              <p class="label">犬主姓名：</p>
              <span style="text-align: left;" [attr.title]="breedModel?.ownerName">
                {{ breedModel?.ownerName }}
              </span>
            </div>
          </div>
        </ng-container>
        <ng-template #typeTemplate>
          <div nz-col nzSm="24">
            <div class="item-wrap">
              <p class="label">单位名称：</p>
              <span style="text-align: left;" [attr.title]="breedModel?.ownerName">
                {{ breedModel?.ownerName }}
              </span>
            </div>
          </div>
        </ng-template>

        <div nz-col nzSm="24">
          <div class="item-wrap">
            <p class="label">犬名：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petName">
              {{breedModel?.petName}}
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="steps-action">
    <button nz-button nzType="primary" (click)="next()">打印/写卡</button>
    <button nz-button nzType="default" (click)="back()">继续登记</button>
  </div>
</ng-template>

<!-- 个人养犬信息打印内容 -->
<ng-template #print let-breed>
  <div style="min-height: 230px;">
    <div class="steps-content">
      <div nz-row>
        <div nz-col>
          <div class="item-wrap">
            <p class="label">电子标识码：</p>
            <nz-input-group [nzSuffix]="suffixTemplate" nzSize="small" style="width: 220px;">
              <input id="tagNoInput" #tagNoInput nz-input placeholder="电子标识码" [(ngModel)]="tagNo" minlength="15"
                maxlength="15" (focus)="tagNoFocus($event)" (keyup.enter)="onTagNoEnter($event)" />
            </nz-input-group>
            <p style="font-size: 12px; color: red; margin: 0 0 0 12px;">
              <i nz-icon nzType="info-circle"></i>
              <span style="margin-left: 10px;">如若无法正常输入，请将输入法切换到英语模式！</span>
            </p>
          </div>
        </div>
      </div>

      <!-- 个人养犬登记信息 -->
      <div nz-row *ngIf="breedModel && breedModel.ownerType === 1">
        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬登记证号码：</p>
            <span style="text-align: left;" [attr.title]="cardNo">{{cardNo}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬主姓名：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.ownerName">
              {{breedModel?.ownerName}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">证件号码：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.certificateNo">
              {{breedModel?.certificateNo}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬名：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petName">{{breedModel?.petName}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬性别：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petGender">
              {{breedModel?.petGender | dictName:'DogGender' | async}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬种：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petKindId">
              {{breedModel?.petKindId | petKindName:'DogKindId' | async}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">毛色：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.hairColor">{{breedModel?.hairColor}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬地址：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.address">{{breedModel?.address}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">登记机关：</p>
            <span style="text-align: left;" [attr.title]="(breedModel?.organizationCode | organizationName | async)">
              {{ breedModel?.organizationCode | organizationName | async }}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">登记日期：</p>
            <span style="text-align: left;" [attr.title]="(breedModel?.created | date: 'yyyy-MM-dd')">
              {{breedModel?.created | date: 'yyyy-MM-dd'}}
            </span>
          </div>
        </div>
      </div>

      <!-- 单位养犬登记信息 -->
      <div nz-row *ngIf="breedModel && breedModel.ownerType === 2">
        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬登记证号码：</p>
            <span style="text-align: left;" [attr.title]="cardNo">{{cardNo}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">公司名称：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.ownerName">
              {{breedModel?.ownerName}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">社会统一信用代码：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.unifiedCode">
              {{breedModel?.unifiedCode}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬人姓名：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.breedName">
              {{breedModel?.breedName}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">证件号码：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.certificateNo">
              {{breedModel?.certificateNo}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">联系电话：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.applyPhone">
              {{breedModel?.applyPhone}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬名：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petName">
              {{breedModel?.petName}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬性别：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petGender">
              {{breedModel?.petGender | dictName:'DogGender' | async}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">犬种：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.petKindId">
              {{breedModel?.petKindId | petKindName:'DogKindId' | async}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">毛色：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.hairColor">
              {{breedModel?.hairColor}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬地址：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.address">
              {{breedModel?.address}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">登记机关：</p>
            <span style="text-align: left;" [attr.title]="(breedModel?.organizationCode | organizationName | async)">
              {{ breedModel?.organizationCode | organizationName | async }}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">登记日期：</p>
            <span style="text-align: left;" [attr.title]="(breedModel?.created | date: 'yyyy-MM-dd')">
              {{breedModel?.created | date: 'yyyy-MM-dd'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="steps-action">
    <button nz-button nzType="primary" [nzLoading]="loading" (click)="printClick()">打印</button>
    <button nz-button nzType="primary" [nzLoading]="writingLoading" (click)="writeCardClick()">写卡</button>
    <button nz-button nzType="default" (click)="back()">继续办理业务</button>
  </div>
</ng-template>

<!-- 制卡内容 -->
<ng-template #writeCard let-breed>
  <div style="min-height: 230px;">
    <div class="steps-content">
      <div nz-row>
        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">养犬登记证号码：</p>
            <span style="text-align: left;" [attr.title]="cardNo">{{cardNo}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label" style="width:140px">犬只电子标志号码：</p>
            <span style="text-align: left;" [attr.title]="tagNo">{{tagNo}}</span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label">登记日期：</p>
            <span style="text-align: left;" [attr.title]="(breedModel?.created | date: 'yyyy-MM-dd')">
              {{breedModel?.created | date: 'yyyy-MM-dd'}}
            </span>
          </div>
        </div>

        <div nz-col nzSm="24" nzMd="12" nzXl="12">
          <div class="item-wrap">
            <p class="label" style="width:140px">下次年检到期日期：</p>
            <span style="text-align: left;" [attr.title]="breedModel?.created">
              {{breedModel?.created | date: 'yyyy-MM-dd'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="steps-action">
    <button nz-button nzType="primary" [nzLoading]="writingLoading" (click)="writeCardClick()">写卡</button>
    <button nz-button nzType="default" (click)="back()">继续办理业务</button>
  </div>
</ng-template>

<!-- 清空模板 -->
<ng-template #suffixTemplate>
  <i nz-icon nz-tooltip nzTooltipTitle="清空" nzType="close-circle" style="cursor: pointer;"
    (click)="tagNoClear($event)"></i>
</ng-template>